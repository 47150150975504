import React from 'react';
import { Link } from 'react-scroll';
import { useDispatch, useSelector } from "react-redux";
import { signout } from '../../actions/userAction';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import { useNavigate } from 'react-router-dom';
import LangSelector from '../../components/language/LangSelector';

const HomeModalMenu = (props) => {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  const signoutHandler = () => {
    dispatch(signout());
    window.location.reload();
  }
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  var badgeContent = localStorage.getItem('cartItems');
  return (
    <nav className="menu-primary">
      <ul className="nav flex-column">
        {userInfo ?
          <li className="nav-item">
            <Badge color="secondary" style={{ color: "white" }} badgeContent={JSON.parse(badgeContent) !== null ? JSON.parse(badgeContent).length : 0}>
              <h3>Cart</h3>
              <ShoppingCartIcon style={{ color: "#273b4a", marginLeft: "10px" }} onClick={() => navigate(`/cart`)}>
              </ShoppingCartIcon>
            </Badge>
          </li> : <></>}
        <li className="nav-item">
          <Link onClick={props.action} to="about-us" title="About Us" href="#about-us" smooth={true} duration={700}>{t('About Us.1')}</Link>
        </li>

        <li className="nav-item">
          <Link onClick={props.action} to="services" title="Services" href="#services" smooth={true} duration={700}>{t('Services.1')}</Link>
        </li>

        <li className="nav-item">
          <Link onClick={props.action} to="our-team" title="Prediction" href="#our-team" smooth={true} duration={700}>{t('Prediction.1')}</Link>
        </li>

        <li className="nav-item">
          <Link onClick={props.action} to="testimonials" title="Testimonials" href="offers" smooth={true} duration={700}>{t('Offers.1')}</Link>
        </li>

        <li className="nav-item">
          <a href="/fgm">FGM</a>
        </li>

        <li className="nav-item">
          <Link onClick={props.action} to="news" title="News" href="#news" smooth={true} duration={700}>{t('Products.1')}</Link>
        </li>

        <li className="nav-item">
          <Link onClick={props.action} to="contacts" title="Contacts" href="#contacts" smooth={true} duration={700}>{t('Contacts.1')}</Link>
        </li>
        <LangSelector />
        {userInfo ? (

          <li className="nav-item dropdown">
            <Link to="#">
              {userInfo.name}
              <i className="fa fa-caret-down"></i>
            </Link>
            <ul className="dropdown-content">
              <li>
                <a href={process.env.PUBLIC_URL + "/dashboard"}>Dashboard</a>
              </li>
              <>
                <li>
                  <Link to="signout" onClick={signoutHandler}>
                    {t("Sign Out.1")}
                  </Link>
                </li>
              </>
            </ul>
          </li>
        ) :
          (<li id="signin" className="nav-item">
            <a to="signin" title="Signin" href="/signin" smooth={true} duration={700}>{t('Signin.1')}</a>
          </li>)}
      </ul>
    </nav>
  );
};

export default HomeModalMenu;
