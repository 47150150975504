import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Routes } from 'react-router-dom';
import Home from "./pages/Home";
import NewsSinglePost from "./pages/NewsSinglePost";
import SearchResults from "./pages/SearchResults";
import Cart from "./pages/Cart";
import SigninScreen from "./pages/SigninScreen";
import page404 from "./pages/404";
import UserInterface from "./pages/UserInterface";
import OrderListScreen from './pages/OrderListScreen';
import RegisterScreen from "./pages/RegisterScreen";
import UserListScreen from './pages/UserListScreen';
import ProfileScreen from './pages/ProfileScreen';
import ShippingAddressScreen from "./pages/ShippingAddressScreen";
import PlaceOrderScreen from "./pages/PlaceOrderScreen";
import OrderScreen from "./pages/OrderScreen";
import OrderHistoryScreen from "./pages/OrderHistoryScreen";
import ProductListScreen from "./pages/ProductListScreen";
import ProductEditScreen from "./pages/ProductEditScreen";
import UserEditScreen from './pages/UserEditScreen';
import DashboardScreen from './pages/DashboardScreen';
import ImplantsScreen from './pages/ImplantsScreen';
import ProductCateg from "./pages/ProductCateg";
import MapScreen from './pages/MapScreen';
import ForgetPassword from './pages/ForgetPassword';
import OfferScreen from "./pages/OfferScreen";
import UserListOffers from "./pages/UserListOffers";
import PaymentMethodScreen from './pages/PaymentMethod';
import CategoriesScreen from "./pages/CategoriesScreen";
import FgmScreen from "./pages/FgmScreen";
function App(res) {
  let id;
  var str = window.location.href;
  var n = str.lastIndexOf("/");
  var l = str.lastIndexOf(":");
  var result = str.substring(n + 1);
  if (str.split(":")[3] !== undefined) {
    var h = str.split(":")[3][0];
  }
  const content = (
    <Route
      exact
      path={`${process.env.PUBLIC_URL + "/product" + "/" + result}`}
      element={<NewsSinglePost />}
    />
  );
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/signin"
          element={<SigninScreen />}
        />
        <Route
          exact
          path="/"
          element={<Home />}
        />
        <Route
          exact
          path="/register"
          element={<RegisterScreen />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/shipping"}`}
          element={<ShippingAddressScreen />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/placeorder"}`}
          element={<PlaceOrderScreen />}
        />
        <Route
          path="/profile/:id"
          element={<ProfileScreen />}
        ></Route>
        <Route path="/userlist" element={<UserListScreen />}></Route>
        <Route path="/userlistoffers" element={<UserListOffers />}></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/orderhistory"}`}
          element={<OrderHistoryScreen />}
        ></Route>
        <Route
          path="/productlist"
          element={<ProductListScreen />}
          exact
        ></Route>
        <Route
          exact
          path="/signin/forget-password"
          element={<ForgetPassword />}
        ></Route>
        <Route
          path="/product/:id/edit"
          element={<ProductEditScreen />}
          exact
        ></Route>
        <Route path="/payment" element={<PaymentMethodScreen />}></Route>
        <Route path="/order/:id" element={<OrderScreen />}></Route>
        <Route
          path="/user/:id/edit"
          element={<UserEditScreen />}
        ></Route>
        <Route
          path="/orderlist"
          element={<OrderListScreen />}
          exact
        ></Route>
        <Route
          exact
          path="/dashboard"
          element={<DashboardScreen />}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/cart/:_id"}`}
          element={<Cart />}
        />
        <Route
          exact
          path="/cart"
          element={<Cart />}
        />
        <Route
          path='/products/:res'
          element={<ProductCateg />}
        />
        <Route
          path="/offers"
          element={<OfferScreen res={res} />} />
        <Route
          path="/fgm"
          element={<FgmScreen res={res} />} />
        <Route
          exact
          path="/categories"
          element={<CategoriesScreen />}
        ></Route>
        <Route
          path="/map" element={<MapScreen />} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/" + res}`}
          element={<ImplantsScreen />}
        />
        <>{content}</>
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/search-results"}`}
          element={<SearchResults />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/ui"}`}
          element={<UserInterface />}
        />
        <Route exact element={page404} />
      </Routes>
    </Router>
  );
}
export default App;
