import React, { Fragment, useRef, useState, useEffect } from 'react';
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, listUsers } from '../actions/userAction';
import LoadingBox from '../components/loadmore/LoadMore';
import MessageBox from '../components/MessageBox';
import { USER_DETAILS_RESET } from '../constants/userConstants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    createCategories,
    deleteCategories,
    listCategories,
} from '../actions/categoriesAction';
import {
    CATEGORIES_CREATE_RESET,
    CATEGORIES_DELETE_RESET,
} from '../constants/categoriesConstant';
import { SearchOutlined, WindowsFilled } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from "react-highlight-words";
import { useTranslation } from 'react-i18next';

export default function CategoriesScreen() {
    const userList = useSelector((state) => state.userList);
    let location = useLocation();
    const navigate = useNavigate();
    const { _id } = useParams();
    const categoriesList = useSelector((state) => state.categoriesList);
    const { loading, error, categories } = categoriesList;
    const categoriesDelete = useSelector((state) => state.categoriesDelete);
    const {
        loading: loadingDelete,
        error: errorDelete,
        success: successDelete,
    } = categoriesDelete;
    const redirect = location.search
        ? location
        : '/dashboard';
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    let data = [];
    const { t, i18n } = useTranslation();
    if (categories !== undefined) {
        Object.keys(categories).map((key) => {
            let obj = {
                key: key,
                id: categories !== undefined ? categories[key] !== undefined ? categories[key]._id : "-" : "-",
                category: categories !== undefined ? categories[key] !== undefined ? categories[key].name : "-" : "-",
                actions: <>
                    <Button
                        type="button"
                        className="small"
                        style={{ fontSize: "16px" }}
                        onClick={(event) => deleteHandler(event, categories[key]._id)}
                    >
                        Delete
                    </Button>
                </>
            }
            data.push(obj);
        })
    }


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '30%',
            ...getColumnSearchProps('id'),
        },
        {
            title: 'CATEGORY',
            dataIndex: 'category',
            key: 'category',
            width: '20%',
            ...getColumnSearchProps('category'),
        },
        {
            title: t('ACTIONS.1'),
            dataIndex: 'actions',
            key: 'actions',
            width: '20%',
            ...getColumnSearchProps('actions'),
        },
    ];
    const deleteHandler = (event, categories) => {
        // event.preventDefault();
        // if (window.confirm('Are you sure?')) {
        dispatch(
            deleteCategories(categories)
        );

        // }
    };
    useEffect(() => {
        // if (successCreate) {
        //     dispatch({ type: PRODUCT_CREATE_RESET });
        //     navigate(`/product/${createdProduct._id}/edit`);
        // }
        if (successDelete) {
            dispatch({ type: CATEGORIES_DELETE_RESET });
        } else {
            dispatch(listCategories());
        }
    }, [successDelete, dispatch]);

    const addCategories = (e) => {
        e.preventDefault();
        let name = prompt("Please enter your name", "Harry Potter");
        if (name != null) {
            dispatch(
                createCategories(name)
            );
        }
    }
    return (
        <div>
            {/* {loadingDelete && <LoadingBox></LoadingBox>}
            {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
            {loadingCreate && <LoadingBox></LoadingBox>}
            {errorCreate && <MessageBox variant="danger">{errorCreate}</MessageBox>} */}
            {loading ? (
                <LoadingBox></LoadingBox>
            ) : error ? (
                <MessageBox variant="danger">{error}</MessageBox>
            ) : (
                <Fragment>
                    <MetaTags>
                        <meta charSet="UTF-8" />
                        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                        <meta
                            name="viewport"
                            content="width=device-width, initial-scale=1"
                        />
                        <meta name="description" content="" />
                        <meta name="keywords" content="" />
                        <meta name="robots" content="index, follow, noodp" />
                        <meta name="googlebot" content="index, follow" />
                        <meta name="google" content="notranslate" />
                        <meta name="format-detection" content="telephone=no" />
                    </MetaTags>
                    <div className="row" style={{ display: "flex", justifyContent: "end" }}>
                        <Button type="button" className="primary" onClick={(e) => addCategories(e)}>
                            Add Categories
                        </Button>
                    </div>
                    <div style={{ overflowX: "auto", marginTop: "5%" }}>
                        <Table columns={columns} dataSource={data} />;
                    </div>
                </Fragment>
            )
            }
        </div >
    );
}
