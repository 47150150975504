import React from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome, faXingSquare, } from '@fortawesome/free-brands-svg-icons'
const HomeInfo = () => {
    library.add(fas, faXingSquare, faFontAwesome)
    const { t } = useTranslation();
    return (
        <section id="information" className="block">
            <div className="wrapper">
                <div className="row no-gutters information-items">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="information information-color-1">
                            <div className="information-icon">
                                <i className="hosco-icon-call-2"></i>
                                <i class="fas fa-envelope" style={{ display: "flex", justifyContent: "center" }}></i>
                            </div>
                            <div className='informations'>
                                <div>
                                    <h4 className="information-t-head">{t('Emergency Call.1')}</h4>
                                    <div className="information-btn">
                                        <a title="Call us now" className="btn btn-sm btn-link btn-line p-0 border-0 min-w-auto link-no-space text-uppercase" href="tel:+355693319100">{t('Call us now.1')}</a>
                                    </div>
                                </div><br />
                                <div>
                                    <h4 className="information-t-head">{t('Email')}</h4>
                                    <div className="information-btn">
                                        <a className="btn btn-sm btn-link btn-line p-0 border-0 min-w-auto link-no-space text-uppercase" href="mailto:info@prodental.al">
                                            {t('Email us.1')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="information information-color-2 information-icon-margin">
                            <div className="information-icon">
                                <i className="hosco-icon-dental-care"></i>
                            </div>

                            <h4 className="information-t-head">{t('Where Are We?.1')}</h4>

                            <div className="information-description">
                                <p>{t("Street.1")} Dritan Hoxha, Pallatet Hawai, Kulla 2, Tirane</p>
                            </div>

                            <div className="information-btn">
                                <Link to="google-maps" title="See on map" className="btn btn-sm btn-link btn-line p-0 border-0 min-w-auto link-no-space text-uppercase scroll-to-id" href="#google-maps">{t('See on map.1')}</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="information information-color-3">
                            <div className="information-icon">
                                <i className="hosco-icon-calendar"></i>
                            </div>

                            <h4 className="information-t-head">{t('Opening Hours.1')}</h4>

                            <div className="information-description">
                                <p><span className="d-flex justify-content-between flex-wrap"><span>{t("Mon - Fri.1")}:</span><span className="text-right">8:00am - 7:00pm</span></span></p>
                                <p><span className="d-flex justify-content-between flex-wrap"><span>{t("Saturday.1")}:</span><span className="text-right">9:00am - 6:00pm</span></span></p>
                                <p><span className="d-flex justify-content-between flex-wrap"><span>{t("Sunday.1")}:</span><span className="text-right">{t('Closed.1')}</span></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeInfo;
