import Axios from "axios";
import {
  CART_ADD_ITEM, CART_REMOVE_ITEM, CART_ADD_ITEM_FAIL, CART_SAVE_SHIPPING_ADDRESS, CART_SAVE_PAYMENT_METHOD
} from "../constants/cartConstants";

export const addToCart = (_id, qty) => async (dispatch, getState) => {
  const { data } = await Axios.get(`/api/products/${_id}`);
  const {
    cart: { cartItems },
  } = getState();
  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      id: data._id,
      name: data.name,
      image: data.image,
      price: data.price,
      discount: data.discount,
      discountPrice: data.discountPrice,
      startDate: data.startDate,
      endDate: data.endDate,
      countInStock: data.countInStock,
      product: data._id,
      seller: data.seller,
      qty,
      isOffer: data.isOffer,
      isUserOffer: data.isUserOffer
    },
  });
  localStorage.setItem(
    'cartItems',
    JSON.stringify(getState().cart.cartItems)
  );
}

export const removeFromCart = (productId) => (dispatch, getState) => {
  dispatch({ type: CART_REMOVE_ITEM, payload: productId });
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};
export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_SHIPPING_ADDRESS, payload: data });
  localStorage.setItem('shippingAddress', JSON.stringify(data));
};
export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_PAYMENT_METHOD, payload: data });
};