import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listUsers, reset } from '../actions/userAction';
import { listOffers, deleteOffers, updateOffer } from '../actions/offersAction';
import LoadingBox from '../components/loadmore/LoadMore';
import MessageBox from '../components/MessageBox';
import { USER_DETAILS_RESET } from '../constants/userConstants';
import emailjs from 'emailjs-com';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from "react-highlight-words";
export default function UserListOffers() {
  const userList = useSelector((state) => state.userList);
  const navigate = useNavigate();
  let location = useLocation();
  const { loading, error, users } = userList;
  const [approved, setApproved] = useState(false);
  const offerList = useSelector((state) => state.offerList);
  const { offers } = offerList;
  const userDelete = useSelector((state) => state.userDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = userDelete;
  const redirect = location.search
    ? location
    : '/userlist';
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  let data = [];
  if (offers !== undefined) {
    Object.keys(offers).map((key) => {
      let obj = {
        key: key,
        id: offers !== undefined ? offers[key] !== undefined ? offers[key]._id : "-" : "-",
        name: users !== undefined ?
          Object.keys(users).map((user) => {
            return offers !== undefined ? users[user] !== undefined ? users[user]._id == offers[key].user ?
              users[user].name : "" : "" : ""
          }) : "-",
        email: users !== undefined ?
          Object.keys(users).map((user) => {
            return offers !== undefined ? users[user] !== undefined ? users[user]._id == offers[key].user ?
              users[user].email : "" : "" : ""
          }) : "-",
        description: offers !== undefined ? offers[key] !== undefined ? offers[key].description : "-" : "-",
        price: offers !== undefined ? offers[key] !== undefined ? offers[key].price : "-" : "-",
        currency: offers !== undefined ? offers[key] !== undefined ? offers[key].currency : "-" : "-",
        status:
          offers !== undefined ? offers[key] !== undefined ? offers[key].approved ?
            <td >Approved</td> : <td id="price">Not Approved</td> : "-" : "-"
        ,
        actions:
          users !== undefined ?
            Object.keys(users).map((user) => {
              return offers !== undefined ? users[user] !== undefined ? users[user]._id == offers[key].user ?
                <>
                  <Button
                    type="button"
                    className="small"
                    onClick={(e) => approveOffer(users[user].name, users[user].email, offers[key].price, offers[key].description, offers[key].approved, offers[key]._id, offers[key].currency)}
                  >
                    Approve
                  </Button>
                  <Button
                    type="button"
                    className="small"
                    onClick={() => deleteOffer(offers[key]._id, offers[key].description, offers[key].price, offers[key].approved)}
                  >
                    Delete
                  </Button> </> : "" : "" : ""
            }) : "-",
      }
      data.push(obj)
    })
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '30%',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '20%',
      ...getColumnSearchProps('description'),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: '20%',
      ...getColumnSearchProps('price'),
      sorter: (a, b) => a.price - b.price,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      width: '20%',
      ...getColumnSearchProps('currency'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      ...getColumnSearchProps('status'),
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      ...getColumnSearchProps('actions'),
    },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listUsers());
    dispatch(listOffers())
    dispatch({
      type: USER_DETAILS_RESET,
    });
  }, [dispatch, redirect, successDelete]);
  const deleteOffer = (id, description, price, approved) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteOffers(id));
      window.location.reload();
    }
  };
  const [result, showResult] = useState(false);
  const approveOffer = (name, email, price, description, approved, id, currency) => {
    if (approved == false) {
      approved = true;
      dispatch(updateOffer({ id, description, price, approved, currency }));
    }
    emailjs.send("service_25h311w", "template_l5kkjvs", {
      name: name,
      email: email,
      price: price,
      description: description,
      currency: currency,
    }, "aReESUg2KqQUmjHUt")
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    reset()
    showResult(true);
    setTimeout(() => {
      showResult(false);
    })
  }
  return (
    <div>
      {loadingDelete && <LoadingBox></LoadingBox>}
      {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
      {successDelete && (
        <MessageBox variant="success">User Deleted Successfully</MessageBox>
      )}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div style={{ overflowX: "auto", marginTop: "5%" }}>
          <Table columns={columns} dataSource={data} />
        </div>
      )}
    </div>
  );
}
