import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AccordionItems from '../../data/services/accordionTwoItems.json';

const Accordion2 = () => {
    const [open, setOpen] = useState(0);

    const handleClick = (e) => {
        let target = e.target.getAttribute("data-target");

        if (open === target) {
            setOpen("#collapse-0");
        } else {
            setOpen(target);
        }
    };

    const { t } = useTranslation();

    return (
        <div id="accordion-services-1" className="accordion accordion-services">
            {AccordionItems && AccordionItems.map((item, key) => {
                return (
                    <div key={key} className={"card " + (open === ("#collapse-" + item.id) ? "active" : " ")}>
                        <div className="card-header" id={"heading-" + item.id}>
                            <button onClick={(e) => handleClick(e)} className={"btn btn-lg btn-link stretched-link " + (open === ("#collapse-" + item.id) ? " " : "collapsed")} type="button" data-toggle="collapse" data-target={"#collapse-" + item.id} aria-expanded="false" aria-controls={"collapse-" + item.id}>
                                <span className="h4 after" style={{ color: "white" }}>{t(item.title)}</span>
                                <i className="fas fa-angle-right i-large"></i>
                            </button>
                        </div>

                        <Collapse in={open === ("#collapse-" + item.id)}>
                            <div id={"collapse-" + item.id} className="collapse show" aria-labelledby={"heading-" + item.id} data-parent={"#accordion-services-" + item.id}>
                                <div className="card-body">
                                    <p>{t(item.description)} </p>

                                    <p className="card-price">{t(item.price)}</p>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                );
            })}
        </div>
    );
};

export default Accordion2;
