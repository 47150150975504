import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOrder, listOrders } from '../actions/orderAction';
import LoadingBox from '../components/loadmore/LoadMore';
import MessageBox from '../components/MessageBox';
import { ORDER_DELETE_RESET } from '../constants/orderConstants';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from "react-highlight-words";
import { useTranslation } from 'react-i18next';
export default function OrderListScreen() {
  const navigate = useNavigate();
  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;
  const orderDelete = useSelector((state) => state.orderDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = orderDelete;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  let data = [];
  const { t, i18n } = useTranslation();
  if (orders !== undefined) {
    Object.keys(orders).map((key) => {
      let obj = {
        key: key,
        id: orders !== undefined ? orders[key] !== undefined ? orders[key]._id : "-" : "-",
        user: orders !== undefined ? orders[key] !== undefined ? orders[key].user?.name : "-" : "-",
        date: orders !== undefined ? orders[key] !== undefined ? orders[key].createdAt : "-" : "-",
        total: orders !== undefined ? orders[key] !== undefined ? orders[key].totalPrice : "-" : "-",
        paid: orders !== undefined ? orders[key] !== undefined ? JSON.stringify(orders[key].isPaid) : "-" : "-",
        method: orders !== undefined ? orders[key] !== undefined ? orders[key].paymentMethod == undefined ? "-" : orders[key].paymentMethod : "-" : "-",
        delivered: orders !== undefined ? orders[key] !== undefined ? JSON.stringify(orders[key].isDelivered) : "-" : "-",
        actions: <>  <Button
          type="button"
          className="small"
          style={{ fontSize: "16px" }}
          onClick={() => {
            navigate(`/order/${orders[key]._id}`);
          }}
        >
          Details
        </Button>
          <Button
            type="button"
            className="small"
            style={{ fontSize: "16px" }}
            onClick={() => deleteHandler(orders[key])}
          >
            Delete
          </Button></>,
      }
      data.push(obj);
    })
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '30%',
      ...getColumnSearchProps('id'),
    },
    {
      title: t('USER.1'),
      dataIndex: 'user',
      key: 'user',
      width: '20%',
      ...getColumnSearchProps('user'),
    },
    {
      title: t('DATE.1'),
      dataIndex: 'date',
      width: '20%',
      key: 'date',
      ...getColumnSearchProps('date'),
      sorter: (a, b) => Number(new Date(a.date)) - Number(new Date(b.date)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('TOTAL.1'),
      dataIndex: 'total',
      key: 'total',
      width: '20%',
      ...getColumnSearchProps('total'),
    },
    {
      title: t('PAID.1'),
      dataIndex: 'paid',
      key: 'paid',
      width: '20%',
      ...getColumnSearchProps('paid'),
      sorter: (a, b) => a.paid.length - b.paid.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('METHOD.1'),
      dataIndex: 'method',
      key: 'method',
      width: '20%',
      ...getColumnSearchProps('method'),
    },
    {
      title: t('DELIVERED.1'),
      dataIndex: 'delivered',
      key: 'delivered',
      width: '20%',
      ...getColumnSearchProps('delivered'),
      sorter: (a, b) => a.delivered.length - b.delivered.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('ACTIONS.1'),
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      ...getColumnSearchProps('actions'),
    },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: ORDER_DELETE_RESET });
    dispatch(listOrders(userInfo._id));
  }, [dispatch, successDelete, userInfo._id]);
  const deleteHandler = (order) => {
    if (window.confirm('Are you sure to delete?')) {
      dispatch(deleteOrder(order._id));
    }
  };
  return (
    <div>
      {loadingDelete && <LoadingBox></LoadingBox>}
      {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div style={{ overflowX: "auto", marginTop: "5%" }}>
          <Table columns={columns} dataSource={data} />
        </div>
      )}
    </div>
  );
}
