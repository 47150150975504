import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addToCart, removeFromCart } from "../actions/cartActions";
import MessageBox from "../components/MessageBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import "../index.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Row, Select, Button } from "antd";
import jwt from "jwt-decode";
import { signout } from "../actions/userAction";
import moment from "moment";

const Cart = (props) => {
  let location = useLocation();
  const { _id } = useParams();
  const navigate = useNavigate();
  const qty = location.search ? Number(location.search.split("=")[1]) : 1;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  var [date, setDate] = useState(new Date());
  var name;
  var price;
  var description;
  var countInStock;
  var id;
  var discountPrice;
  id = _id;
  cartItems.map((e) => {
    name = e.name;
    price = e.price;
    description = e.description;
    countInStock = e.countInStock;
    discountPrice = e.discountPrice;
  });
  const { Option } = Select;
  let stock = [];
  for (var i = 0; i <= countInStock; i++) {
    stock.push(i);
  }
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(addToCart(id, qty));
    }
  }, [dispatch, id, qty]);
  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };
  const checkoutHandler = () => {
    if (userInfo) {
      const token = jwt(userInfo.token);
      if (token.exp * 1000 < Date.now()) {
        dispatch(signout());
        window.location.reload();
      }
    }
    navigate("/signin?redirect=shipping");
  };
  const signinFirst = (props) => {
    navigate(`/signin?redirect=/product/${id}`);
  };
  var dateInitial = moment(date).format("YYYY-MM-DD HH:mm");
  const { t, i18n } = useTranslation();
  const handleChange = (value, id) => {
    dispatch(addToCart(id.id, Number(value)));
  };
  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>
      <Loading />
      <Header />
      <div className="row top">
        <div className="product_background" >
          <div className="overlay_product_background"></div>
          <div className="container_product_background">
            <h2 className="shop_label">
              CART</h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div><a href="/">Home</a></div>
              <div style={{ marginLeft: "10px" }}>{">"}</div>
              <div style={{ marginLeft: "10px", color: "white" }}>Cart</div>
            </div>
          </div>
        </div>
        <div className="col-1" style={{ marginTop: "5%" }}>
          <h1 style={{ paddingLeft: "10px" }}> {t("ShoppingCart.1")} </h1>{" "}
          {cartItems.length === 0 ? (
            <MessageBox>
              {t("CartIsEmpty.1")} <Link to="/"> {t("GoShopping.1")} </Link>
            </MessageBox>
          ) : (
            <ul>
              {cartItems.map((item) =>
                window.matchMedia("(max-width: 425px)").matches ? (
                  <Row
                    className="cart_product"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      marginBottom: "2%"
                    }}
                  >
                    <Row span={6}>
                      <img
                        src={item.image}
                        alt={item.name}
                        className="small"
                      ></img>
                    </Row>
                    <Row span={6}>
                      <a
                        style={{ fontSize: "17px" }}
                        href={
                          process.env.PUBLIC_URL + "/product" + "/" + item.id
                        }
                      >
                        {item.name}
                      </a>
                    </Row>
                    <Row span={6}>
                      <div
                        style={{
                          display: "flex ",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Select
                          style={{ padding: "10px", width: "auto" }}
                          value={item.qty}
                          onChange={handleChange}
                        >
                          {stock.map((x) => (
                            <Option key={x + 1} value={x + 1} id={item.product}>
                              {x + 1}
                            </Option>
                          ))}
                        </Select>
                        {userInfo !== null ? (
                          <h4 style={{ display: "flex", alignItems: "center" }}>
                            {dateInitial >= moment(item.startDate).format("YYYY-MM-DD HH:mm") && dateInitial < moment(item.endDate).format("YYYY-MM-DD HH:mm") && item.isOffer == false && item.isUserOffer == false
                              ? item.discountPrice || item.price
                              : item.price}
                            ALL
                          </h4>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Row>
                    <Row span={6}>
                      <Button
                        type="button"
                        onClick={() => removeFromCartHandler(item.product)}
                      >
                        {t("Delete.1")}
                      </Button>
                    </Row>
                  </Row>
                ) : (
                  <Row style={{ display: "flex", alignItems: "center", marginBottom: "2%" }}>
                    <Col span={6}>
                      <img
                        src={item.image}
                        alt={item.name}
                        className="small"
                      ></img>
                    </Col>
                    <Col span={6}>
                      <a
                        style={{ fontSize: "17px" }}
                        href={
                          process.env.PUBLIC_URL + "/product" + "/" + item.id
                        }
                      >
                        {item.name}
                      </a>
                    </Col>
                    <Col span={6}>
                      <div
                        style={{
                          display: "flex ",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Select
                          style={{ padding: "10px", width: "auto" }}
                          value={item.qty}
                          onChange={handleChange}
                        >
                          {stock.map((x) => (
                            <Option key={x + 1} value={x + 1} id={item.product}>
                              {x + 1}
                            </Option>
                          ))}
                        </Select>
                        {userInfo !== null ? (
                          <h4 style={{ display: "flex", alignItems: "center" }}>
                            {dateInitial >= moment(item.startDate).format("YYYY-MM-DD HH:mm") && dateInitial < moment(item.endDate).format("YYYY-MM-DD HH:mm") && item.isOffer == false && item.isUserOffer == false
                              ? item.discountPrice || item.price
                              : item.price}
                            ALL
                          </h4>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Col>
                    <Col span={6}>
                      <Button
                        type="button"
                        onClick={() => removeFromCartHandler(item.product)}
                      >
                        {t("Delete.1")}
                      </Button>
                    </Col>
                  </Row>
                )
              )}
            </ul>
          )}{" "}
        </div>{" "}
        <div className="col-1" style={{ marginTop: "11%" }}>
          <div className="card card-body">
            <ul>
              <li>
                {" "}
                {userInfo !== null ? (
                  <h2>
                    {t("Subtotal.1")}({cartItems.reduce((a, c) => a + c.qty, 0)}
                    {t("items.1")}) :
                    {cartItems.reduce(
                      (a, c) =>
                        a +
                        (dateInitial >= moment(c.startDate).format("YYYY-MM-DD HH:mm") && dateInitial < moment(c.endDate).format("YYYY-MM-DD HH:mm") && c.isOffer == false && c.isUserOffer == false
                          ? c.discountPrice || c.price
                          : c.price) *
                        c.qty,
                      0
                    )}
                    ALL
                  </h2>
                ) : (
                  <h2>
                    {t("Subtotal.1")}
                    <a onClick={() => signinFirst(props)}>
                      <FontAwesomeIcon icon={faLock}> </FontAwesomeIcon>{" "}
                    </a>{" "}
                  </h2>
                )}{" "}
              </li>{" "}
              <li>
                <button
                  type="button"
                  onClick={checkoutHandler}
                  className="primary block addCartButton1"
                  disabled={cartItems.length === 0}
                >
                  {t("ProceedToCheckout.1")}
                </button>{" "}
              </li>{" "}
            </ul>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
      <div
        style={{
          marginTop: "150px",
        }}
      >
        <Footer> </Footer>{" "}
      </div>{" "}
    </Fragment>
  );
};
export default Cart;
