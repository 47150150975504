import React, { Fragment, useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import './Modal.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { createOffers } from '../../actions/offersAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome, faXingSquare, } from '@fortawesome/free-brands-svg-icons'

export function OfferModal(props) {
    library.add(fas, faXingSquare, faFontAwesome)
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [productcateg, setProductcateg] = useState("");
    const [currency, setCurrency] = useState('');
    const [itemCount, setItemCount] = useState(1);
    const [cartItems, setCartItems] = useState([]);
    const dispatch = useDispatch();
    const productList = useSelector((state) => state.productList);
    const { loading, error, products } = productList;
    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;
    const [inputValue, setInputValue] = useState("");
    const userDetails = useSelector((state) => state.userDetails);
    const { user } = userDetails;
    const offer = useSelector((state) => state.offersCreate);
    const { offers } = offer;
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const userOffer = () => {
        dispatch(createOffers({
            description,
            price,
            currency
        }));
        window.location.reload();
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="modal-offer"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    <p style={{ color: "#006e7f", fontSize: "23px" }}>{t("Suggest Your Offer.1")}!</p>
                </Modal.Title>
                <div onClick={props.onHide}>
                    <i className="fas fa-times i-default"></i>
                </div>
            </Modal.Header>
            <Modal.Body >
                <form className="form" id="offerForm" onSubmit={userOffer} style={{ color: "#006e7f" }}>
                    <div>
                        <label htmlFor="price" style={{ color: "#006e7f" }}>{t("Price.1")}</label>
                        <input
                            id="price"
                            type="number"
                            placeholder={t("Enter price.1")}
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            required
                        ></input>
                    </div>
                    <div>
                        <label htmlFor="currency" style={{ color: "#006e7f" }}>{t("Currency.1")}</label>
                        <select name="currency" id="currency" onChange={(e) => setCurrency(e.target.value)}>
                            <option >Currency</option>
                            <option value="Lek">ALL</option>
                            <option value="Euro">EUR</option>
                            <option value="Dollar">US</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="description" style={{ color: "#006e7f" }}>{t("Description.1")}</label>
                        <textarea
                            id="description"
                            rows="3"
                            type="text"
                            placeholder={t("Enter description.1")}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        ></textarea>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" style={{ backgroundColor: "#006e7f", color: "white" }} onClick={props.onHide && userOffer}> {t("Update.1")}</Button>
            </Modal.Footer>
        </Modal>
    )
}