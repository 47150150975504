import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import "react-pro-sidebar/dist/css/styles.css";
import LoadMore from "../components/loadmore/LoadMore";
import { useDispatch, useSelector } from "react-redux";
import MessageBox from "../components/MessageBox";
import { listProducts } from "../actions/productActions";
import { useState } from "react";
import App from "../App";
import { Card, Navbar, Nav, Container } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom'
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import { isMobile } from 'react-device-detect';
import moment from "moment-timezone";
import { Tabs, Pagination, Button, Dropdown, } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { listCategories } from '../actions/categoriesAction';

function ProductCateg() {
  document.body.classList.add("blog");
  const { res } = useParams();
  let categ = "";
  if (res !== undefined) {
    categ = res;
  }
  const imagePerRow = 9;
  const [image, setImage] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [sort, setSort] = useState('')
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;
  const categoriesList = useSelector((state) => state.categoriesList);
  const { categories } = categoriesList;
  const userSignin = useSelector((state) => state.userSignin);
  const [date, setDate] = useState(new Date());
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [next, setNext] = useState(imagePerRow);
  const [pageSize, setPageSize] = useState(6)
  const [current, setCurrent] = useState(1)
  const [minIndex, setMinIndex] = useState(0)
  const [maxIndex, setMaxIndex] = useState(pageSize)
  const [closeDropdown, setCloseDropdown] = useState(false)
  useEffect(() => {
    dispatch(listProducts());
    dispatch(listCategories());
  }, []);
  if (categ !== "") {
    App(categ);
  }
  const onAdd = (product) => {
    const exist = cartItems.find(x => x._id === product._id);
    if (exist) {
      setCartItems(cartItems.map((x) => x._id === product._id ? {
        ...exist, qty: exist.qty + 1
      } : x
      ))
    } else {
      setCartItems([...cartItems, { ...product, qty: 1 }])
    }
  };
  const onRemove = (product) => {
    const exist = cartItems.find(x => x._id === product._id);
    if (exist) {
      setCartItems(cartItems.map((x) => x._id === product._id ?
        exist.qty >= 2 ? ({
          ...exist, qty: exist.qty - 1
        }) : x : x
      ))
    } else {
      setCartItems([...cartItems, { ...product, qty: 1 }])
    }
  };
  const addToCartHandler = (product) => {
    navigate(`/cart/${product._id}?qty=${product.qty}`);
    navigate(-1);
  };

  var dateInitial = moment(date).format("YYYY-MM-DD HH:mm");
  var badgeContent = localStorage.getItem('cartItems');

  const ChangeCategory = (e) => {
    setPageSize(6)
    setCurrent(1)
    setMinIndex(0)
    setMaxIndex(pageSize)
    navigate(process.env.PUBLIC_URL + "/products/" + e)
  }
  const string = window.location.href.split('/products/')[1];
  const decode_string = decodeURI(string)
  var test = []
  if (products !== undefined) {
    const headers = Object.keys(products).map((key) => {
      if (products[key].category == categ && products[key].countInStock > 0 && products[key].isOffer !== true) {
        test.push(products[key])
      }
    })
    if (image == true) {
      if (sort == "sort_newness") {
        const sortedDates = Object.keys(test)?.map(obj => { return { ...test[obj], date: new Date(test[obj].createdAt) } }).sort((a, b) => b.date - a.date);
        test = []
        test = sortedDates
      } else if (sort == "sort_highprice") {
        const sortedDates = Object.keys(test)?.map(obj => { return { ...test[obj], price: test[obj].discountPrice == null ? test[obj].price : test[obj].discountPrice } }).sort((a, b) => b.price - a.price);
        test = []
        test = sortedDates
      } else if (sort == "sort_lowprice") {
        const sortedDates = Object.keys(test)?.map(obj => { return { ...test[obj], price: test[obj].discountPrice == null ? test[obj].price : test[obj].discountPrice } }).sort((a, b) => a.price - b.price);
        test = []
        test = sortedDates
      }
      else if (sort == "sort_discountPrice") {
        const date1 = moment(date).format("YYYY-MM-DD HH:mm");
        const sortedDates = Object.keys(test)?.map(obj => { return { ...test[obj], discountprice: test[obj].discountPrice } }).filter((a, b) => a.discountprice !== null && b.discountprice !== null && a.endDate > date1).sort((a, b) => b.discountprice - a.discountprice);
        test = []
        test = sortedDates
      }
    }
  }
  const handleMoreImage = (value, sort) => {
    if (value == true) {
      setSort(sort)
      setImage(value)
      setNext(next);
    } else {
      setNext(next + imagePerRow);
    }
  };
  const items = [
    {
      key: '1',
      label: (
        <a style={{ color: "rgb(108, 108, 108)" }} onClick={() => handleMoreImage(true, "sort_newness")} rel="noopener noreferrer" >
          Sort By Newness
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a style={{ color: "rgb(108, 108, 108)" }} onClick={() => handleMoreImage(true, "sort_highprice")} rel="noopener noreferrer" >
          Sort By High Price
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a style={{ color: "rgb(108, 108, 108)" }} onClick={() => handleMoreImage(true, "sort_lowprice")} rel="noopener noreferrer" >
          Sort By Low Price
        </a>
      ),
    },
    {
      key: '4',
      label: (
        <a style={{ color: "rgb(108, 108, 108)" }} onClick={() => handleMoreImage(true, "sort_discountPrice")} rel="noopener noreferrer" >
          Sort By Discount
        </a>
      ),
    },
  ];
  const onShowSizeChange = (current) => {
    setCurrent(current);
    setMinIndex((current - 1) * pageSize);
    setMaxIndex(current * pageSize);
  };
  const closeDropdownFunction = () => {
    setCloseDropdown(!closeDropdown)
  }
  return (
    <div>
      {loading ? (
        <Loading />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Fragment>
          <MetaTags>
            <meta charSet="UTF-8" />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta name="description" content="" />
            <meta name="keywords" content="" />
            <meta name="robots" content="index, follow, noodp" />
            <meta name="googlebot" content="index, follow" />
            <meta name="google" content="notranslate" />
            <meta name="format-detection" content="telephone=no" />
          </MetaTags>
          <Header />
          <main id="main" className="site-main" style={{ backgroundColor: "rgb(248, 248, 248)" }}>
            <div id="page-content">
              <div className="product_background" >
                <div className="overlay_product_background"></div>
                <div className="container_product_background">
                  <h2 className="shop_label">SHOP</h2>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div><a href="/">Home</a></div>
                    <div style={{ marginLeft: "10px" }}>{">"}</div>
                    <div style={{ marginLeft: "10px", color: "white" }}>Shop</div>
                  </div>
                </div>
              </div>
              <div className="product_dropdown_sort">
                <Dropdown menu={{ items }} placement="bottomLeft">
                  <Button className="button_load" onClick={() => setCloseDropdown(!closeDropdown)}>Sort <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: "16px" }} /></Button>
                </Dropdown>
              </div>
              <div className="wrapper">
                <div className="content">
                  <div id="blog">
                    {categories !== undefined ? <Tabs
                      defaultActiveKey={decode_string}
                      tabPosition={isMobile ? "top" : "right"}
                      size="large"
                      onChange={ChangeCategory}
                      style={{
                        fontFamily: "Poppins, sans-serif", color: "#566491", fontWeight: "600"
                      }}
                      items={
                        Object.keys(categories).map((key, name) => {
                          return {
                            label: categories[key].name !== "FGM" ? categories[key].name : "",
                            key: categories[key].name !== "FGM" ? categories[key].name : "",
                            children: categories[key].name !== "FGM" ? <div class="col-sm-8 col-sm-8 col-sm-8 col-sm-12" style={{ display: "flex", flexDirection: "column", marginRight: " 20%" }}>
                              <div className="row gutter-width-md with-pb-lg" style={{ alignItems: "center" }}>
                                {Object.keys(test).slice(minIndex, maxIndex)?.map((key) => {
                                  if (test[key].category == categ && test[key].countInStock > 0 && test[key].isOffer !== true) {
                                    return (
                                      <Card style={{ width: "18rem", display: "flex", alignItems: "center" }}>
                                        {test[key].endDate !== undefined && test[key].startDate !== undefined ?
                                          dateInitial >= moment(test[key].startDate).format("YYYY-MM-DD HH:mm") && dateInitial < moment(test[key].endDate).format("YYYY-MM-DD HH:mm") && test[key].discount > 0 ?
                                            <div className="price">-{test[key].discount}<img src="/assets/img/placeholder/discount.png" style={{ width: "50px", height: "unset", objectFit: "unset" }}></img></div> : <div className="price"></div> : <div className="price"></div>}
                                        <div className="image_product">
                                          <a
                                            className="imageSize"
                                            href={
                                              process.env.PUBLIC_URL +
                                              "/product" +
                                              "/" +
                                              test[key]._id
                                            }
                                          >
                                            <Card.Img
                                              variant="top"
                                              type="image"
                                              src={test[key].image}
                                              style={{ objectFit: "contain", boxShadow: "20px 20px 50px 15px grey", height: "100%" }}

                                            // accept="image/*"
                                            />
                                          </a>
                                          <h5 style={{ textAlign: "center", paddingTop: "30px" }}>{test[key].name}</h5>
                                        </div>
                                        <div className="title_product">
                                          <div class="product-item-inner">
                                            <div class="product actions product-item-actions ">
                                              <div class="actions-primary ">
                                                <form
                                                  data-role="tocart-form"
                                                  action=""
                                                >
                                                  <input
                                                    type="hidden"
                                                    name="product"
                                                    value="6450"
                                                  ></input>
                                                  <input
                                                    type="hidden"
                                                    name="uenc"
                                                    value=""
                                                  ></input>
                                                  <div class="qty-box">
                                                    <a class="qtyminus">
                                                      <RemoveIcon
                                                        fontSize="small"
                                                        style={{
                                                          color: "#006e7f",
                                                        }}
                                                        onClick={() =>
                                                          onRemove(test[key])
                                                        }
                                                      />
                                                    </a>
                                                    {Object.keys(cartItems).map((y) => {
                                                      if (cartItems[y] !== undefined && cartItems[y]._id == test[key]._id) {
                                                        return (
                                                          <>
                                                            <div
                                                              title="Qtà"
                                                              class="input-text qty"
                                                              data-validate="null"
                                                              style={{ border: "none" }}
                                                            >
                                                              {
                                                                cartItems[y].qty
                                                              }
                                                            </div>
                                                          </>
                                                        )
                                                      }
                                                    })}
                                                    <a class="qtyplus">
                                                      <AddIcon
                                                        fontSize="small"
                                                        style={{
                                                          color: "#006e7f",
                                                        }}
                                                        onClick={() =>
                                                          onAdd(test[key])
                                                        }
                                                      />
                                                    </a>
                                                  </div>
                                                  {Object.keys(cartItems).map((y) => {
                                                    if (cartItems[y] !== undefined && cartItems[y]._id == test[key]._id) {
                                                      return (
                                                        <>
                                                          <button
                                                            onClick={() => addToCartHandler(cartItems[y])}
                                                            type="submit"
                                                            title="Aggiungi al carrello"
                                                            class="action tocart primary"
                                                          >
                                                            <i class="fa fa-shopping-cart icon"></i>
                                                          </button>
                                                        </>
                                                      )
                                                    }
                                                  })}
                                                </form>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Card>
                                    );
                                  }
                                })}
                              </div>
                              <Pagination
                                pageSize={pageSize}
                                current={current}
                                total={test.length}
                                onChange={onShowSizeChange}
                              // defaultCurrent={3}
                              //pageSize={pageSize}
                              // total={}
                              />


                            </div> : <></>
                          };
                        })}
                    /> : <></>}
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </Fragment>
      )
      }
    </div >
  );
};
export default ProductCateg;
