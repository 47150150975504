import React, { useEffect } from 'react';
import Swiper from 'swiper';
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import TestimonialsData from '../../data/testimonials/testimonialsData.json';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import moment from "moment";

const Testimonials = () => {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        new Swiper('.adv-slider-reviews .adv-swiper-container', {
            noSwipingClass: 'adv-swiper-no-swiping',
            containerModifierClass: 'adv-swiper-container-',
            slideClass: 'adv-swiper-slide',
            slideBlankClass: 'adv-swiper-slide-invisible-blank',
            slideActiveClass: 'adv-swiper-slide-active',
            slideDuplicateActiveClass: 'adv-swiper-slide-duplicate-active',
            slideVisibleClass: 'adv-swiper-slide-visible',
            slideDuplicateClass: 'adv-swiper-slide-duplicate',
            slideNextClass: 'adv-swiper-slide-next',
            slideDuplicateNextClass: 'adv-swiper-slide-duplicate-next',
            slidePrevClass: 'adv-swiper-slide-prev',
            slideDuplicatePrevClass: 'adv-swiper-slide-duplicate-prev',
            wrapperClass: 'adv-swiper-wrapper',
            pagination: {
                bulletClass: 'adv-swiper-pagination-bullet',
                bulletActiveClass: 'adv-swiper-pagination-bullet-active',
                modifierClass: 'adv-swiper-pagination-',
                currentClass: 'adv-swiper-pagination-current',
                totalClass: 'adv-swiper-pagination-total',
                hiddenClass: 'adv-swiper-pagination-hidden',
                progressbarFillClass: 'adv-swiper-pagination-progressbar-fill',
                progressbarOppositeClass: 'adv-swiper-pagination-progressbar-opposite',
                clickableClass: 'adv-swiper-pagination-clickable',
                lockClass: 'adv-swiper-pagination-lock',
                el: '.adv-swiper-pagination-2',
                clickable: true
            },
            speed: 600,
            parallax: true
        });
    }, []);
    const productList = useSelector((state) => state.productList);
    var { loading, error, products } = productList;
    var dateInitial = moment(date).format("YYYY-MM-DD HH:mm");
    const { t, i18n } = useTranslation();
    var listOffer = [];

    return (
        <div id="testimonials" className="block bg-gray-light spacer p-top-xl p-bottom-xl reviews-width-img">
            <div className="wrapper">
                <div style={{
                    backgroundClip: "#000",
                    opacity: ".7",
                }}></div>
                <div className="adv-slider-reviews">
                    <div className="adv-swiper-container">
                        <div className="adv-swiper-wrapper reviews-items">
                            {products !== undefined ?
                                products && products.map((item, key) => {
                                    if (item.isOffer === true && item.endDate !== undefined) {
                                        if (item.countInStock > 0 && dateInitial >= moment(item.startDate).format("YYYY-MM-DD HH:mm") && dateInitial < moment(item.endDate).format("YYYY-MM-DD HH:mm")) {
                                            return (
                                                <div key={key} className="adv-swiper-slide reviews-item">
                                                    <div className="reviews-item-img" data-swiper-parallax="-400">
                                                        <img src={item.image} alt={item.author} className={isMobile ? "testimonialImageMobile" : "testimonialImage"} />
                                                    </div>

                                                    <h4 className="reviews-item-t-head" data-swiper-parallax="-300">{item.author}</h4>

                                                    <p className="reviews-item-positions" data-swiper-parallax="-200">{item.position}</p>

                                                    <div className="reviews-item-description" data-swiper-parallax="-100">
                                                        <p>{item.text}</p>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <>
                                                </>
                                            )
                                        }
                                    }
                                }) : <></>}
                        </div>
                        <div className="adv-swiper-pagination-2 no-space"></div>
                    </div>
                </div>
            </div>
            <div className="spacer m-top-lg text-right">
                <a
                    title="View all news"
                    className="btn btn-primary"
                    href={process.env.PUBLIC_URL + "/offers"}
                >
                    {t('Offers.1')}
                </a>
            </div>
        </div>
    );
};

export default Testimonials;
