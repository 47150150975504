import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "../../actions/productActions";
import 'react-slideshow-image/dist/styles.css';
import { useTranslation } from "react-i18next";
import { Carousel } from 'antd';

const HomeNews = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(listProducts());
  }, []);
  return (
    <section id="news" className="block spacer p-top-xl">
      <div className="wrapper">
        <div className="title">
          <h2 style={{
            marginLeft: "43%",
            fontSize: "40px"
          }}>{t('Our Products.1')}</h2>
        </div>
        <Carousel autoplay style={{ marginLeft: "10%" }}>
          <div style={{ width: "100%", height: "40%", background: 'center', backgroundColor: '#385bb2', backgroundRepeat: 'no-repeat' }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img src="/uploads/1630797291429.jpg" style={{ width: "30%", background: 'center' }}>
              </img>
              <img src="/uploads/1632819292211.jpg" style={{ width: "30%", background: 'center' }}>
              </img>
              <img src="/uploads/1632821842860.jpg" style={{ width: "30%", background: 'center' }}>
              </img>
            </div>
          </div>
          <div style={{ width: "40%", height: "40%", background: 'center', backgroundColor: '#385bb2', backgroundRepeat: 'no-repeat' }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img src="/uploads/1632823017246.jpg" style={{ width: "30%", background: 'center' }}>
              </img>
              <img src="/uploads/1632822491864.jpg" style={{ width: "30%", background: 'center' }}>
              </img>
              <img src="/uploads/1632819723743.jpg" style={{ width: "30%", background: 'center' }}>
              </img>
            </div>
          </div>
          <div style={{ width: "40%", height: "40%", background: 'center', backgroundColor: '#385bb2', backgroundRepeat: 'no-repeat' }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img src="/uploads/1632819047799.jpg" style={{ width: "30%", background: 'center' }}>
              </img>
              <img src="/uploads/1630970643117.jpg" style={{ width: "30%", background: 'center' }}>
              </img>
              <img src="/uploads/1632822280268.jpg" style={{ width: "30%", background: 'center' }}>
              </img>
            </div>
          </div>
        </Carousel>

        <div className="spacer m-top-lg text-right">
          <a
            title="View all news"
            className="btn btn-primary"
            href={process.env.PUBLIC_URL + "/products/Endodonti"}
          >
            {t('View All Products.1')}
          </a>
        </div>
      </div>
    </section >
  );
};

export default HomeNews;
