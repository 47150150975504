import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useLocation, useNavigate } from 'react-router-dom';
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { register } from '../actions/userAction';
import Loading from "../blocks/loading/Loading";
import MessageBox from '../components/MessageBox';
import { Stack, Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
export default function RegisterScreen() {
  const [name, setName] = useState('');
  const [nipt, setNipt] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  let location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const redirect = location.search
    ? location.search.split('=')[1]
    : '/';
  const userRegister = useSelector((state) => state.userRegister);
  const { userInfo, loading, error } = userRegister;
  let validNipt = new RegExp(
    '^[a-zA-Z0][Z0-9.-][Z0-9.-][Z0-9.-][Z0-9.-][Z0-9.-][Z0-9.-][Z0-9.-][Z0-9.-][a-zA-Z0]$'
  );
  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage('Password and confirm password are not match');
    } else {
      dispatch(register(name, nipt, email, password));
    }
  };
  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);
  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>
      <Loading />
      <Header />
      <div>
        <form className="form" onSubmit={submitHandler}>
          <div>
            <h1>{t('Create your account.1')}</h1>
          </div>
          {loading && <Loading></Loading>}
          {error || errorMessage ? <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              <strong>{error ? error.startsWith('E11000') ? t("emailDuplicate.1") : t(error + '.1') : t(errorMessage + '.1')}</strong>
            </Alert>
          </Stack> : <></>}
          <div>
            <label htmlFor="name">{t('Name.1')}</label>
            <input
              type="text"
              id="name"
              placeholder="Enter name"
              required
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter email"
              required
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>
          <div>
            <label htmlFor="nipt">NIPT</label>
            <input
              type="text"
              id="nipt"
              placeholder="Enter NIPT"
              required
              onChange={(e) => setNipt(e.target.value)}
            ></input>
          </div>
          <div>
            <label htmlFor="password">{t('Password.1')}</label>
            <input
              type="password"
              id="password"
              placeholder="Enter password"
              required
              onChange={(e) => setPassword(e.target.value)}
            ></input>
          </div>
          <div>
            <label htmlFor="confirmPassword">{t('confirm Password.1')}</label>
            <input
              type="password"
              id="confirmPassword"
              placeholder="Enter confirm password"
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></input>
          </div>
          <div>
            <label />
            <button className="primary" type="submit">
              {t('Register.1')}
            </button>
          </div>
          <div>
            <label />
            <div style={{ fontSize: "17px", fontWeight: "500" }}>
              {t('Already have an account.1')}
              <Link to={`/signin?redirect=${redirect}`}>{t('Signin.1')}</Link>
            </div>
          </div>
        </form>
        <Footer />
      </div>
    </Fragment>
  );
}