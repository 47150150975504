import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import "react-pro-sidebar/dist/css/styles.css";
import LoadMore from "../components/loadmore/LoadMore";
import { useDispatch, useSelector } from "react-redux";
import MessageBox from "../components/MessageBox";
import { listProducts } from "../actions/productActions";
import { useState } from "react";
import App from "../App";
import { Card, Navbar, Nav, Container } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { OfferModal } from "../components/modal/OfferModal.js";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import { isMobile } from 'react-device-detect';
import moment from "moment";

const OfferScreen = () => {
  document.body.classList.add("blog");
  let { params } = useParams();
  let categ = "";
  if (params !== undefined) {
    categ = params.res;
  }
  const [productcateg, setProductcateg] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const userDetails = useSelector((state) => state.userDetails);
  const offer = useSelector((state) => state.offersCreate);
  const { offers } = offer;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    dispatch(listProducts());
  }, []);
  if (categ !== "") {
    App(categ);
  }
  const onAdd = (product) => {
    const exist = cartItems.find(x => x._id === product._id);
    if (exist) {
      setCartItems(cartItems.map((x) => x._id === product._id ? {
        ...exist, qty: exist.qty + 1
      } : x
      ))
    } else {
      setCartItems([...cartItems, { ...product, qty: 1 }])
    }
  };
  const onRemove = (product) => {
    const exist = cartItems.find(x => x._id === product._id);
    if (exist) {
      setCartItems(cartItems.map((x) => x._id === product._id ?
        exist.qty >= 2 ? ({
          ...exist, qty: exist.qty - 1
        }) : x : x
      ))
    } else {
      setCartItems([...cartItems, { ...product, qty: 1 }])
    }
  };
  const addToCartHandler = (product) => {
    navigate(`/cart/${product._id}?qty=${product.qty}`);
    navigate(-1);
  };
  var dateInitial = moment(date).format("YYYY-MM-DD HH:mm");
  const [modalShowOffer, setModalShowOffer] = React.useState(false);
  var badgeContent = localStorage.getItem('cartItems');
  return (
    <div>
      {loading ? (
        <Loading></Loading>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Fragment>
          <MetaTags>
            <meta charSet="UTF-8" />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta name="description" content="" />
            <meta name="keywords" content="" />
            <meta name="robots" content="index, follow, noodp" />
            <meta name="googlebot" content="index, follow" />
            <meta name="google" content="notranslate" />
            <meta name="format-detection" content="telephone=no" />
          </MetaTags>
          <Loading />
          <Header />
          <div className="product_background" >
            <div className="overlay_product_background"></div>
            <div className="container_product_background">
              <h2 className="shop_label">OFFERS</h2>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div><a href="/">Home</a></div>
                <div style={{ marginLeft: "10px" }}>{">"}</div>
                <div style={{ marginLeft: "10px", color: "white" }}>Offers</div>
              </div>
            </div>
          </div>
          <main id="main" className="site-main">
            <div id="page-content" className="spacer p-top-xl">
              <div className="wrapper">
                <div className="content">
                  <div id="blog">
                    <div className="row gutter-width-md">
                      <div className="col-sm-6 col-sm-6 col-sm-6 col-sm-12">
                        <div className="row gutter-width-md with-pb-lg" style={{ alignItems: "center" }}>
                          <OfferModal
                            show={modalShowOffer}
                            onHide={() => setModalShowOffer(false)}
                          />
                          {Object.keys(products).map((key) => {
                            if (userInfo !== null && userInfo !== undefined) {
                              if ((products[key].isOffer === true || (products[key].isUserOffer === true && userInfo.email == products[key].category)) && products[key].countInStock > 0 && products[key].endDate != "") {
                                if (dateInitial >= moment(products[key].startDate).format("YYYY-MM-DD HH:mm") && dateInitial < moment(products[key].endDate).format("YYYY-MM-DD HH:mm")) {
                                  return (
                                    <>
                                      <Card style={{ width: "18rem", display: "flex", alignItems: "center" }}>
                                        {products[key].endDate !== undefined && products[key].startDate !== undefined ?
                                          dateInitial >= moment(products[key].startDate).format("YYYY-MM-DD HH:mm") && dateInitial < moment(products[key].endDate).format("YYYY-MM-DD HH:mm") && products[key].discount > 0 ?
                                            <div className="price">-{products[key].discount}<img src="/assets/img/placeholder/discount.png" style={{ width: "50px", height: "unset", objectFit: "unset" }}></img></div> : <div className="price"></div> : <div className="price"></div>}
                                        <div>
                                          <a
                                            href={
                                              process.env.PUBLIC_URL +
                                              "/product" +
                                              "/" +
                                              products[key]._id
                                            }
                                          >
                                            <Card.Img
                                              variant="top"
                                              type="image"
                                              src={products[key].image}
                                              style={{ objectFit: "cover", boxShadow: "20px 20px 50px 15px grey", height: "fit-content" }}

                                            // accept="image/*"
                                            />
                                          </a>
                                        </div>
                                        <div className="title_product">
                                          <div class="product-item-inner">
                                            <div class="product actions product-item-actions ">
                                              <div class="actions-primary ">
                                                <form
                                                  data-role="tocart-form"
                                                  action=""
                                                >
                                                  <input
                                                    type="hidden"
                                                    name="product"
                                                    value="6450"
                                                  ></input>
                                                  <input
                                                    type="hidden"
                                                    name="uenc"
                                                    value=""
                                                  ></input>
                                                  <div class="qty-box">
                                                    <a class="qtyminus">
                                                      <RemoveIcon
                                                        fontSize="small"
                                                        style={{
                                                          color: "#006e7f",
                                                        }}
                                                        onClick={() =>
                                                          onRemove(products[key])
                                                        }
                                                      />
                                                    </a>
                                                    {Object.keys(cartItems).map((y) => {
                                                      if (cartItems[y] !== undefined && cartItems[y]._id == products[key]._id) {
                                                        return (
                                                          <>
                                                            <div
                                                              title="Qtà"
                                                              class="input-text qty"
                                                              data-validate="null"
                                                              style={{ border: "none" }}
                                                            >
                                                              {
                                                                cartItems[y].qty
                                                              }
                                                            </div>
                                                          </>
                                                        )
                                                      }
                                                    })}
                                                    <a class="qtyplus">
                                                      <AddIcon
                                                        fontSize="small"
                                                        style={{
                                                          color: "#006e7f",
                                                        }}
                                                        onClick={() =>
                                                          onAdd(products[key])
                                                        }
                                                      />
                                                    </a>
                                                  </div>
                                                  {Object.keys(cartItems).map((y) => {
                                                    if (cartItems[y] !== undefined && cartItems[y]._id == products[key]._id) {
                                                      return (
                                                        <>
                                                          <button
                                                            onClick={() => addToCartHandler(cartItems[y])}
                                                            type="submit"
                                                            title="Aggiungi al carrello"
                                                            class="action tocart primary"
                                                          >
                                                            <i class="fa fa-shopping-cart icon"></i>
                                                          </button>
                                                        </>
                                                      )
                                                    }
                                                  })}
                                                </form>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Card>
                                    </>
                                  );
                                }
                              }
                            }
                          })}
                        </div>
                        <LoadMore />
                      </div>
                      <div className="col-sm-6 col-sm-6 col-sm-6 col-sm-12">
                        <div className="row gutter-width-md with-pb-lg" style={{ alignItems: "center" }}>
                          <OfferModal
                            show={modalShowOffer}
                            onHide={() => setModalShowOffer(false)}
                          />
                          {Object.keys(products).map((key) => {
                            if (userInfo !== null && userInfo !== undefined) {
                              if (dateInitial >= moment(products[key].startDate).format("YYYY-MM-DD HH:mm") && dateInitial < moment(products[key].endDate).format("YYYY-MM-DD HH:mm") && products[key].discount > 0 && products[key].isOffer == false && products[key].isUserOffer == false) {
                                return (
                                  <>
                                    <Card style={{ width: "18rem" }}>
                                      <div className="price">-{products[key].discount}<img src="/assets/img/placeholder/discount.png" style={{ width: "50px", height: "unset", objectFit: "unset" }}></img></div>
                                      <Card.Img
                                        variant="top"
                                        src={products[key].image}
                                        alt={products[key].name}
                                      />
                                      <Card.Body>
                                        <a
                                          href={
                                            process.env.PUBLIC_URL +
                                            "/product" +
                                            "/" +
                                            products[key]._id
                                          }
                                        >
                                          <Card.Title>
                                            {products[key].name}
                                          </Card.Title>
                                        </a>
                                      </Card.Body>
                                      <div className="title">
                                        <div class="product-item-inner">
                                          <div class="product actions product-item-actions ">
                                            <div class="actions-primary ">
                                              <form
                                                data-role="tocart-form"
                                                action=""
                                              >
                                                <input
                                                  type="hidden"
                                                  name="product"
                                                  value="6450"
                                                ></input>
                                                <input
                                                  type="hidden"
                                                  name="uenc"
                                                  value=""
                                                ></input>
                                                <div class="qty-box">
                                                  <a class="qtyminus">
                                                    <RemoveIcon
                                                      fontSize="small"
                                                      style={{
                                                        color: "#006e7f",
                                                      }}
                                                      onClick={() =>
                                                        onRemove(products[key])
                                                      }
                                                    />
                                                  </a>
                                                  {Object.keys(cartItems).map((y) => {
                                                    if (cartItems[y] !== undefined && cartItems[y]._id == products[key]._id) {
                                                      return (
                                                        <>
                                                          <div
                                                            title="Qtà"
                                                            class="input-text qty"
                                                            data-validate="null"
                                                            style={{ border: "none" }}
                                                          >
                                                            {
                                                              cartItems[y].qty
                                                            }
                                                          </div>
                                                        </>
                                                      )
                                                    }
                                                  })}
                                                  <a class="qtyplus">
                                                    <AddIcon
                                                      fontSize="small"
                                                      style={{
                                                        color: "#006e7f",
                                                      }}
                                                      onClick={() =>
                                                        onAdd(products[key])
                                                      }
                                                    />
                                                  </a>
                                                </div>
                                                {Object.keys(cartItems).map((y) => {
                                                  if (cartItems[y] !== undefined && cartItems[y]._id == products[key]._id) {
                                                    return (
                                                      <>
                                                        <button
                                                          onClick={() => addToCartHandler(cartItems[y])}
                                                          type="submit"
                                                          title="Aggiungi al carrello"
                                                          class="action tocart primary"
                                                        >
                                                          <i class="fa fa-shopping-cart icon"></i>
                                                        </button>
                                                      </>
                                                    )
                                                  }
                                                })}
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Card>
                                  </>
                                );
                              }
                            }
                          })}
                        </div>
                        <LoadMore />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </Fragment>
      )
      }
    </div >
  );
};
export default OfferScreen;
