import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { signout } from '../../actions/userAction';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";

const HeaderMenuPrimary = () => {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  const signoutHandler = () => {
    dispatch(signout());
  }
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  var badgeContent = localStorage.getItem('cartItems');

  return (
    <nav className="menu-primary">
      <ul className="nav">
        <li className="nav-item">
          <a title="About Us" href={process.env.PUBLIC_URL + "/#about-us"}>
            {t('About Us.1')}
          </a>
        </li>

        <li className="nav-item">
          <a title="Services" href={process.env.PUBLIC_URL + "/#services"}>
            {t('Services.1')}
          </a>
        </li>

        <li className="nav-item">
          <a title="Prediction" href={process.env.PUBLIC_URL + "/#our-team"}>
            {t('Prediction.1')}
          </a>
        </li>

        <li className="nav-item">
          <a
            title="Offers"
            href={process.env.PUBLIC_URL + "/offers"}
          >
            {t('Offers.1')}
          </a>
        </li>

        <li
          className={
            "nav-item " +
            (window.location.pathname.includes("/products/Endodonti") ||
              window.location.pathname.includes("/product")
              ? "current-nav-item"
              : "")
          }
        >
          <a title="Products" href={process.env.PUBLIC_URL + "/products/Endodonti"}>
            {t('Products.1')}
          </a>
        </li>

        <li className="nav-item">
          <a
            title="FGM"
            href={process.env.PUBLIC_URL + "/fgm"}
          >
            FGM
          </a>
        </li>
        <li className="nav-item">
          <a title="Contacts" href={process.env.PUBLIC_URL + "/#contacts"}>
            {t('Contacts.1')}
          </a>
        </li>
        <li className="nav-item">
          <Badge color="secondary" badgeContent={JSON.parse(badgeContent) !== null ? JSON.parse(badgeContent).length : 0}>
            <ShoppingCartIcon onClick={() => navigate(`/cart`)}>
            </ShoppingCartIcon>
          </Badge>
        </li>
      </ul>
    </nav>
  );
};

export default HeaderMenuPrimary;
