import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import PageTitleNews from "../blocks/page-title/PageTitleNews";
import Widget from "../blocks/widget/Widget";
import LoadMore from "../components/loadmore/LoadMore";
import { useDispatch, useSelector } from "react-redux";
import MessageBox from "../components/MessageBox";
import { listProducts } from "../actions/productActions";
import { useState } from 'react';
const News = (props) => {
  document.body.classList.add("blog");
  const [productcateg, setProductcateg] = useState('');
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;
  useEffect(() => {
    dispatch(listProducts());
  }, []);
  return (
    <div>
      {loading ? (
        <Loading></Loading>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Fragment>
          <MetaTags>
            <meta charSet="UTF-8" />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta name="description" content="" />
            <meta name="keywords" content="" />
            <meta name="robots" content="index, follow, noodp" />
            <meta name="googlebot" content="index, follow" />
            <meta name="google" content="notranslate" />
            <meta name="format-detection" content="telephone=no" />
          </MetaTags>
          <Loading />
          <Header />
          <div class="containerproduct">
            <div class="row">
              <div class="colproduct">
                <ProSidebar  >
                  <Menu iconShape="square" >
                    <SubMenu title="Products" >
                      <MenuItem >
                        <button
                          value={"Implants"}
                          onClick={(e) => setProductcateg(e.target.value)}
                        >Implants
                        </button>
                      </MenuItem>
                      <MenuItem >
                        <button
                          value={"Component 2"}
                          onClick={(e) => setProductcateg(e.target.value)}
                        >Component 2
                        </button>
                      </MenuItem>
                    </SubMenu>
                  </Menu>
                </ProSidebar>
              </div>
              <div class="col-9">
                <main id="main" className="site-main">
                  <PageTitleNews />
                  <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                      <div className="content">
                        <div id="blog">
                          <div className="row gutter-width-md">
                            <div className="col-sm-6 col-sm-6 col-sm-6 col-sm-12">
                              <div className="row gutter-width-md with-pb-lg">
                                {Object.keys(products).map((key) => {
                                  if (products[key].category == productcateg) {
                                    return (
                                      <div
                                        key={key}
                                      >
                                        <div className="card card-post">
                                          <div className="card-top position-relative">
                                            <a
                                              title={products[key].title}
                                              href={
                                                process.env.PUBLIC_URL +
                                                "/product" +
                                                "/" +
                                                products[key]._id
                                              }
                                            >
                                              <div className="img object-fit overflow-hidden">
                                                <div className="object-fit-cover transform-scale-h">
                                                  <img className="medium" src={products[key].image} alt={products[key].name} />
                                                </div>
                                              </div>
                                            </a>
                                          </div>
                                          <div className="card-body">
                                            <h5 className="card-title">
                                              <a
                                                title={products[key].title}
                                                href={
                                                  process.env.PUBLIC_URL + "/product"
                                                }
                                              >
                                                {products[key].title}
                                              </a>
                                            </h5>
                                            <p className="card-text">
                                              {products[key].name}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                              <LoadMore />
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                              <Widget />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "150px" }}>
            <Footer></Footer>
          </div>
        </Fragment>
      )}
    </div>
  );
};
export default News;
