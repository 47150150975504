import React from 'react';
import scriptLoader from 'react-async-script-loader';

class Maps extends React.Component {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.map = null;
    };

    UNSAFE_componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
        var map;
        var geocoder;

        var address = ['ProDental shpk, Tirana, Albania'];
        const address_latlng = new window.google.maps.LatLng(41.33709818028847, 19.79277873978604);

        if (isScriptLoadSucceed) {
            map = new window.google.maps.Map(this.mapRef.current, {
                center: address_latlng,
                zoom: 19,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: false,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
            });

            var icon = {
                url: 'assets/img/demo/20_img.png',
                size: new window.google.maps.Size(112, 118)
            };

            geocoder = new window.google.maps.Geocoder();

            for (var i = 0; i < address.length; i++) {
                geocoder.geocode({ 'address': address[i] }, function (results, status) {
                    if (status === 'OK') {

                        let marker = new window.google.maps.Marker({
                            position: results[0].geometry.location,
                            icon: icon
                        });

                        marker.setMap(map);
                        map.setCenter(results[0].geometry.location);
                    }
                });
            }

        } else this.props.onError();
    };

    render() {
        return (
            <div id="google-maps" class="footer-no-border" ref={this.mapRef} ></div>
        );
    };
};

export default scriptLoader(['https://maps.googleapis.com/maps/api/js?key=AIzaSyDG0_GQf2jsJk9KX4srsw9agQLYMztb_ww'])(Maps);
