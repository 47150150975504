import React, { useEffect, useState } from "react";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import { useDispatch, useSelector } from 'react-redux';
import '../index.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from "../blocks/loading/Loading";
import { reset } from '../actions/userAction';
import { useTranslation } from 'react-i18next';
const ForgetPassword = (props) => {
    const [email, setEmail] = useState('');
    document.body.classList.add("signin");
    let location = useLocation();
    const redirect = location.search
        ? location.search.split('=')[1]
        : '/';
    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo, error } = userSignin;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(reset(email));
        window.location.reload();
    }
    useEffect(() => {
        if (userInfo) {
            navigate("/");
        }
    }, [navigate, redirect, userInfo]);
    const { t, i18n } = useTranslation();
    return (
        <div>
            <Header />
            <form className="form" onSubmit={submitHandler}>
                <div>
                    <h1>{t('Reset.1')}</h1>
                </div>
                {<Loading></Loading>}
                <div>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        placeholder="Enter email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                    ></input>
                </div>
                <div>
                    <label />
                    <button className="primary" type="submit">
                        {t('Submit.1')}
                    </button>
                </div>
            </form>
            <Footer />
        </div>
    );
};
export default ForgetPassword;
