export const CATEGORIES_LIST_REQUEST = 'CATEGORIES_LIST_REQUEST';
export const CATEGORIES_LIST_SUCCESS = 'CATEGORIES_LIST_SUCCESS';
export const CATEGORIES_LIST_FAIL = 'CATEGORIES_LIST_FAIL';

export const CATEGORIES_DETAILS_REQUEST = 'CATEGORIES_DETAILS_REQUEST';
export const CATEGORIES_DETAILS_SUCCESS = 'CATEGORIES_DETAILS_SUCCESS';
export const CATEGORIES_DETAILS_FAIL = 'CATEGORIES_DETAILS_FAIL';

export const CATEGORIES_CREATE_REQUEST = 'CATEGORIES_CREATE_REQUEST';
export const CATEGORIES_CREATE_SUCCESS = 'CATEGORIES_CREATE_SUCCESS';
export const CATEGORIES_CREATE_FAIL = 'CATEGORIES_CREATE_FAIL';
export const CATEGORIES_CREATE_RESET = 'CATEGORIES_CREATE_RESET';

export const CATEGORIES_DELETE_RESET = 'CATEGORIES_DELETE_RESET';
export const CATEGORIES_DELETE_FAIL = 'CATEGORIES_DELETE_FAIL';
export const CATEGORIES_DELETE_REQUEST = 'CATEGORIES_DELETE_REQUEST';
export const CATEGORIES_DELETE_SUCCESS = 'CATEGORIES_DELETE_SUCCESS';

export const CATEGORIES_UPDATE_REQUEST = 'CATEGORIES_UPDATE_REQUEST';
export const CATEGORIES_UPDATE_SUCCESS = 'CATEGORIES_UPDATE_SUCCESS';
export const CATEGORIES_UPDATE_FAIL = 'CATEGORIES_UPDATE_FAIL';
export const CATEGORIES_UPDATE_RESET = 'CATEGORIES_UPDATE_RESET';

