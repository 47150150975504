import React, { useEffect, useState } from "react";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '../index.css';
import Loading from "../blocks/loading/Loading";
import MessageBox from '../components/MessageBox';
import { signin } from '../actions/userAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack, Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
const SigninScreen = (props) => {
  document.body.classList.add("signin");
  const navigate = useNavigate();
  let location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const redirect = location.search
    ? location.search.split('=')[1]
    : '/';
  const { t, i18n } = useTranslation();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, error } = userSignin;
  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(email, password));
  }
  useEffect(() => {
    if (userInfo) {
      if (location.search == "") {
        navigate("/")
      } else {
        navigate("/shipping");
      }
    }
  }, [props.history, redirect, userInfo]);
  return (
    <div>
      <Header />
      <form className="form" onSubmit={submitHandler}>
        <div>
          <h1>{t('Signin.1')}</h1>
        </div>
        {<Loading></Loading>}
        {error === undefined ? <></> : <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <strong>{t(error + ".1")}</strong>
          </Alert>
        </Stack>}
        <div>
          <label htmlFor="email">Email </label>
          <input
            type="email"
            id="email"
            placeholder="Enter email"
            required
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div>
          <label htmlFor="password">{t('Password.1')}</label>
          <input
            type="password"
            id="password"
            placeholder="Enter password"
            required
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        <div>
          <label />
          <button className="primary" type="submit">
            {t('Signin.1')}
          </button>
        </div>
        <div>
          <label />
          <div style={{ fontSize: "15px", fontWeight: "500" }}>
            {t('forgotPass.1')}
            <Link to="forget-password">{t('Update your password.1')}</Link>
          </div>
          <br></br>
          <div style={{ fontSize: "15px", fontWeight: "500" }}>
            {t('New customer.1')}
            <Link to={`/register?redirect=${redirect}`}>
              {t('Create your account.1')}
            </Link>
          </div>
        </div>
      </form>
      <Footer />
    </div >
  );
};
export default SigninScreen;
