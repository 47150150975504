import React, { useState } from 'react';
import Fade from '@material-ui/core/Fade';
import { useTranslation } from 'react-i18next';

const OurTeam = () => {
    const [open_1, setOpen1] = useState(false);
    const [open_2, setOpen2] = useState(false);
    const [open_3, setOpen3] = useState(false);
    const boxVariant = {
        visible: { opacity: 1, scale: 2 },
        hidden: { opacity: 0, scale: 0 },
    }
    const handleClick = (id) => {
        if (id === 1) {
            setOpen1((prev) => !prev);
        }

        if (id === 2) {
            setOpen2((prev) => !prev);
        }

        if (id === 3) {
            setOpen3((prev) => !prev);
        }

    };
    const { t } = useTranslation();
    function prediction() {
        var predictions = document.querySelectorAll(".prediction");

        for (var i = 0; i < predictions.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = predictions[i].getBoundingClientRect().top;
            var elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                predictions[i].classList.add("active");
            } else {
                predictions[i].classList.remove("active");
            }
        }
    }

    window.addEventListener("scroll", prediction);
    return (
        <section id="our-team" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="row gutter-width-md with-pb-md prediction_section">
                    <div className="prediction">
                        <div className="team-member">
                            <div className="team-member-top">
                                <div className="ourteamimage">
                                    <div className="ourteamimage">
                                        <img className="ourteamimages" src="assets/img/placeholder/mission.png" alt="Tom Henders" />
                                        <div className="team-member-description" style={{ marginLeft: "4%" }}>
                                            <h1 className="team-member-t-head" style={{
                                                color: "#566491", height: "11%", paddingBottom: "11%", paddingBottom: "4%"
                                            }}>{t('Mission.1')}</h1>
                                            <p style={{ fontWeight: 700 }}> {t('Import-eksport.1')} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gutter-width-md with-pb-md prediction_section">
                    <div className="prediction">
                        <div className="team-member">
                            <div className="team-member-top">
                                <div className="ourteamimage">
                                    <div className="ourteamimage">
                                        <img className="ourteamimage_vision" src="assets/img/placeholder/vision.png" alt="Tom Henders" />
                                        <div className="team-member-description" style={{ marginLeft: "4%" }}>
                                            <h1 className="team-member-t-head" style={{ color: "#566491", height: "11%", paddingBottom: "11%" }}>{t('Vision.1')}</h1>
                                            <div className="team-member-description">
                                                <p>{t('To be a leader in the market of dental materials.1')}</p>
                                                <p>{t('To be producers of various innovative products.1')}</p>
                                                <p>{t('To be an exporter to European countries.1')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurTeam;
