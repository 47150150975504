import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, listUsers } from '../actions/userAction';
import LoadingBox from '../components/loadmore/LoadMore';
import MessageBox from '../components/MessageBox';
import { USER_DETAILS_RESET } from '../constants/userConstants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from "react-highlight-words";
import { useTranslation } from 'react-i18next';
export default function UserListScreen() {
  const userList = useSelector((state) => state.userList);
  let location = useLocation();
  const navigate = useNavigate();
  const { _id } = useParams();
  const { loading, error, users } = userList;
  const userDelete = useSelector((state) => state.userDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = userDelete;
  const redirect = location.search
    ? location
    : '/dashboard';
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  let data = [];
  const { t, i18n } = useTranslation();
  if (users !== undefined) {
    Object.keys(users).map((key) => {
      let obj = {
        key: key,
        id: users !== undefined ? users[key] !== undefined ? users[key]._id : "-" : "-",
        name: users !== undefined ? users[key] !== undefined ? users[key].name : "-" : "-",
        email: users !== undefined ? users[key] !== undefined ? users[key].email : "-" : "-",
        status: users !== undefined ? users[key] !== undefined ? JSON.stringify(users[key].status) : "-" : "-",
        isAdmin: users !== undefined ? users[key] !== undefined ? JSON.stringify(users[key].isAdmin) : "-" : "-",
        actions: <>   <Button
          type="button"
          className="small"
          style={{ fontSize: "16px" }}
          onClick={() => navigate(`/user/${users[key]._id}/edit`)}
        >
          Edit
        </Button>
          <Button
            type="button"
            className="small"
            style={{ fontSize: "16px" }}
            onClick={() => deleteHandler(users[key])}
          >
            Delete
          </Button></>,
      }
      data.push(obj);
    })
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '30%',
      ...getColumnSearchProps('id'),
    },
    {
      title: t('Full Name.1'),
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      ...getColumnSearchProps('status'),
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('isAdmin.1'),
      dataIndex: 'isAdmin',
      key: 'isAdmin',
      width: '20%',
      ...getColumnSearchProps('isAdmin'),
    },
    {
      title: t('ACTIONS.1'),
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      ...getColumnSearchProps('actions'),
    },
  ];
  useEffect(() => {
    dispatch(listUsers());
    dispatch({
      type: USER_DETAILS_RESET,
    });
  }, [dispatch, redirect, successDelete]);
  const deleteHandler = (user) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteUser(user._id));
    }
  };
  var status;
  if (users !== undefined) {
    users.map((user) => {
      status = user.status
    })
  }
  return (
    <div>
      {loadingDelete && <LoadingBox></LoadingBox>}
      {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
      {successDelete && (
        <MessageBox variant="success">User Deleted Successfully</MessageBox>
      )}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div style={{ overflowX: "auto", marginTop: "5%" }}>
          <Table columns={columns} dataSource={data} />
        </div>
      )}
    </div>
  );
}
