import React, { Fragment, useEffect } from "react";
import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import "react-pro-sidebar/dist/css/styles.css";
import { useSelector } from "react-redux";
import { Navbar, Nav, Container } from "react-bootstrap";
import OrderListScreen from "./OrderListScreen";
import UserListScreen from "./UserListScreen";
import ProfileScreen from "./ProfileScreen";
import ProductListScreen from "./ProductListScreen";
import UserListOffers from "./UserListOffers";
import OrderHistoryScreen from "./OrderHistoryScreen";
import CategoriesScreen from "./CategoriesScreen";
import Footer from "../blocks/footer/Footer";
import { isMobile } from "react-device-detect";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import jwt from "jwt-decode";
import { signout } from "../actions/userAction";

export default function DashboardScreen() {
  const [showOrderList, setShowOrderList] = React.useState(false);
  const [showUserList, setShowUserList] = React.useState(false);
  const [showProfile, setShowProfile] = React.useState(false);
  const [showProductList, setShowProductList] = React.useState(false);
  const [showUsersOffersList, setShowUsersOffersList] = React.useState(false);
  const [showHistoriaPorosive, setShowHistoriaPorosive] = React.useState(false);
  const [showCategories, setShowCategories] = React.useState(false);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (userInfo == null) {
    dispatch(signout());
    navigate("/signin");
    window.location.reload();
  } else {
    const token = jwt(userInfo.token);
    if (token.exp * 1000 < Date.now()) {
      dispatch(signout());
      navigate("/signin");
    }
  }
  const onClick = (e) => {
    switch (e) {
      case "profili":
        setShowProfile(true);
        setShowOrderList(false);
        setShowUserList(false);
        setShowProductList(false);
        setShowUsersOffersList(false);
        setShowHistoriaPorosive(false);
        setShowCategories(false)
        break;
      case "orderList":
        setShowOrderList(true);
        setShowUserList(false);
        setShowProfile(false);
        setShowProductList(false);
        setShowUsersOffersList(false);
        setShowHistoriaPorosive(false);
        setShowCategories(false)
        break;
      case "userList":
        setShowUserList(true);
        setShowOrderList(false);
        setShowProfile(false);
        setShowProductList(false);
        setShowUsersOffersList(false);
        setShowHistoriaPorosive(false);
        setShowCategories(false)
        break;
      case "productList":
        setShowProductList(true);
        setShowUserList(false);
        setShowOrderList(false);
        setShowProfile(false);
        setShowUsersOffersList(false);
        setShowHistoriaPorosive(false);
        setShowCategories(false)
        break;
      case "usersOffersList":
        setShowUsersOffersList(true);
        setShowProductList(false);
        setShowUserList(false);
        setShowOrderList(false);
        setShowProfile(false);
        setShowHistoriaPorosive(false);
        setShowCategories(false)
        break;
      case "categoriesList":
        setShowCategories(true)
        setShowProfile(false);
        setShowOrderList(false);
        setShowUserList(false);
        setShowProductList(false);
        setShowUsersOffersList(false);
        setShowHistoriaPorosive(false);
        break;
      case "historiaPorosive":
        setShowHistoriaPorosive(true);
        setShowUsersOffersList(false);
        setShowProductList(false);
        setShowUserList(false);
        setShowOrderList(false);
        setShowProfile(false);
        setShowCategories(false)
    }
  };
  var badgeContent = localStorage.getItem("cartItems");
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Loading />
      <Header />
      <Navbar bg="light" variant="light">
        <Container>
          <Navbar.Brand>
            {userInfo ? (
              <Nav.Link onClick={() => onClick("profili")}>
                {t("User Profile.1")}
              </Nav.Link>
            ) : (
              <></>
            )}
            {userInfo && userInfo.isAdmin ? (
              <>
                <Nav.Link onClick={() => onClick("orderList")}>
                  {t("Order List.1")}
                </Nav.Link>
                <Nav.Link onClick={() => onClick("userList")}>
                  {t("User List.1")}
                </Nav.Link>
                <Nav.Link onClick={() => onClick("productList")}>
                  {t("Products List.1")}
                </Nav.Link>
                <Nav.Link onClick={() => onClick("usersOffersList")}>
                  {t("Users Offers List.1")}
                </Nav.Link>
                <Nav.Link onClick={() => onClick("categoriesList")}>
                  {t("Categories List.1")}
                </Nav.Link>
              </>
            ) : (
              <></>
            )}
            {userInfo ? (
              <Nav.Link onClick={() => onClick("historiaPorosive")}>
                {t("Orders History.1")}
              </Nav.Link>
            ) : (
              <></>
            )}
            {isMobile ? (
              <Nav.Link>
                <Badge
                  color="secondary"
                  badgeContent={
                    JSON.parse(badgeContent) !== null
                      ? JSON.parse(badgeContent).length
                      : 0
                  }
                >
                  <ShoppingCartIcon
                    onClick={() => navigate(`/cart`)}
                  ></ShoppingCartIcon>
                </Badge>
              </Nav.Link>
            ) : (
              <></>
            )}
          </Navbar.Brand>
        </Container>
      </Navbar>
      {userInfo ? (
        showProfile ||
          showOrderList ||
          showUserList ||
          showProductList ||
          showUsersOffersList ||
          showHistoriaPorosive ||
          showCategories ? null : (
          <ProfileScreen />
        )
      ) : (
        <></>
      )}
      {showProfile ? <ProfileScreen /> : null}
      {showOrderList ? <OrderListScreen /> : null}
      {showUserList ? <UserListScreen /> : null}
      {showProductList ? <ProductListScreen /> : null}
      {showUsersOffersList ? <UserListOffers /> : null}
      {showHistoriaPorosive ? <OrderHistoryScreen /> : null}
      {showCategories ? <CategoriesScreen /> : null}
      <div style={{ marginTop: "150px" }}>
        <Footer></Footer>
      </div>
    </div>
  );
}
