import {
    CATEGORIES_LIST_REQUEST,
    CATEGORIES_LIST_SUCCESS,
    CATEGORIES_LIST_FAIL,
    CATEGORIES_DETAILS_REQUEST,
    CATEGORIES_DETAILS_SUCCESS,
    CATEGORIES_DETAILS_FAIL,
    CATEGORIES_CREATE_FAIL,
    CATEGORIES_CREATE_REQUEST,
    CATEGORIES_CREATE_SUCCESS,
    CATEGORIES_DELETE_REQUEST,
    CATEGORIES_DELETE_FAIL,
    CATEGORIES_DELETE_SUCCESS,
    CATEGORIES_UPDATE_REQUEST,
    CATEGORIES_UPDATE_SUCCESS,
    CATEGORIES_UPDATE_FAIL,
} from "../constants/categoriesConstant";
import Axios from "axios";

export const listCategories = () => async (dispatch) => {
    dispatch({
        type: CATEGORIES_LIST_REQUEST,
    });
    try {
        const { data } = await Axios.get("/api/categories");
        dispatch({ type: CATEGORIES_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CATEGORIES_LIST_FAIL, payload: error.message });
    }
};

export const createCategories = (name) => async (dispatch, getState) => {
    dispatch({ type: CATEGORIES_CREATE_REQUEST });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        const { data } = await Axios.post(
            '/api/categories',
            { name },
            {
                headers: { Authorization: `Bearer ${userInfo.token}` },
            }
        );
        dispatch({
            type: CATEGORIES_CREATE_SUCCESS,
            payload: data.categories,
        });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: CATEGORIES_CREATE_FAIL, payload: message });
    }
};
export const deleteCategories = (categoriesId) => async (dispatch, getState) => {
    dispatch({ type: CATEGORIES_DELETE_REQUEST, payload: categoriesId });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        const { data } = Axios.delete(`/api/categories/${categoriesId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: CATEGORIES_DELETE_SUCCESS });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: CATEGORIES_DELETE_FAIL, payload: message });
    }
};
export const updateCategories = (categories) => async (dispatch, getState) => {
    dispatch({ type: CATEGORIES_UPDATE_REQUEST, payload: categories });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        const { data } = await Axios.put(`/api/categories/${categories._id}`, categories, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: CATEGORIES_UPDATE_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: CATEGORIES_UPDATE_FAIL, error: message });
    }
};

