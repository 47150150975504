import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createOrder } from '../actions/orderAction';
import CheckoutSteps from '../components/CheckoutSteps';
import { ORDER_CREATE_RESET } from '../constants/orderConstants';
import LoadingBox from '../components/loadmore/LoadMore';
import MessageBox from '../components/MessageBox';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import jwt from 'jwt-decode';
import { signout } from '../actions/userAction';
import moment from "moment";

export default function PlaceOrderScreen() {
  const cart = useSelector((state) => state.cart);
  const navigate = useNavigate();
  var [date, setDate] = useState(new Date());
  var dateInitial = moment(date).format("YYYY-MM-DD HH:mm");
  var startDate;
  var endDate;
  Object.keys(cart.cartItems).map((id) => {
    startDate = cart.cartItems[id].startDate;
    endDate = cart.cartItems[id].endDate;
  })
  if (startDate != undefined || endDate != undefined) {
    startDate = moment(startDate).format("YYYY-MM-DD HH:mm");
    endDate = moment(endDate).format("YYYY-MM-DD HH:mm");
  }
  const orderCreate = useSelector((state) => state.orderCreate);
  const { loading, success, error, order } = orderCreate;
  const toPrice = (num) => Number(num.toFixed(2));
  cart.itemsPrice = toPrice(
    cart.cartItems.reduce((a, c) =>
      a + (dateInitial >= moment(c.startDate).format("YYYY-MM-DD HH:mm") && dateInitial < moment(c.endDate).format("YYYY-MM-DD HH:mm") && c.discount > 0 && c.isOffer == false && c.isUserOffer == false ? c.discountPrice : c.price) * c.qty, 0)
  );
  cart.shippingPrice = cart.itemsPrice >= 5000 || cart.shippingAddress.city == "Tirane" || cart.shippingAddress.city == "Tirana" || cart.shippingAddress.city == "tirane" || cart.shippingAddress.city == "tirana" ? toPrice(0) : toPrice(200);
  cart.totalPrice = cart.itemsPrice
  const userSignin = useSelector((state) => state.userSignin);
  const {
    userInfo
  } = userSignin;
  const dispatch = useDispatch();
  const placeOrderHandler = () => {
    if (userInfo) {
      const token = jwt(userInfo.token);
      if (token.exp * 1000 < Date.now()) {
        dispatch(signout());
        window.location.reload();
      }
    }
    dispatch(createOrder({ ...cart, orderItems: cart.cartItems }));
  };
  useEffect(() => {
    if (success) {
      navigate(`/order/${order._id}`);
      dispatch({ type: ORDER_CREATE_RESET });
    }
  }, [dispatch, order, navigate, success]);
  const { t, i18n } = useTranslation();
  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>
      <Loading />
      <Header />
      <div>
        <CheckoutSteps step1 step2 step3 step4></CheckoutSteps>
        <div className="row top">
          <div className="col-1">
            <ul>
              <li>
                <div className="card card-body">
                  <h2>{t("Shipping.1")}</h2>
                  <p>
                    <strong>{t("Name.1")}:</strong> {cart.shippingAddress.fullName} <br />
                    <strong>{t("Address.1")}: </strong> {cart.shippingAddress.address},
                    {cart.shippingAddress.city}, {cart.shippingAddress.postalCode}
                    ,{cart.shippingAddress.country}
                  </p>
                </div>
              </li>
              <li>
                <div className="card card-body">
                  <h2>{t("Order Items.1")}</h2>
                  <ul>
                    {cart.cartItems.map((item) => (
                      < li key={item.product} >
                        <div className="row" style={{ alignItems: "center" }}>
                          <div>
                            <img
                              src={item.image}
                              alt={item.name}
                              className="small"
                            ></img>
                          </div>
                          <div className="min-30">
                            <Link to={`/product/${item.product}`}>
                              {item.name}
                            </Link>
                          </div>
                          <div>{dateInitial >= moment(item.startDate).format("YYYY-MM-DD HH:mm") && dateInitial < moment(item.endDate).format("YYYY-MM-DD HH:mm") && item.discount > 0 && item.isOffer == false && item.isUserOffer == false ?
                            <div>
                              {item.qty} x {item.discountPrice} = {item.qty * item.discountPrice}ALL
                            </div>
                            :
                            <div>
                              {item.qty} x {item.price} = {item.qty * item.price}ALL
                            </div>}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-1">
            <div className="card card-body">
              <ul>
                <li>
                  <h2>{t("Order Summary.1")}</h2>
                </li>
                <li>
                  <div className="row">
                    <div>{t("items.1")}</div>
                    <div>
                      <div>{cart.itemsPrice.toFixed(2)}ALL</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div>{t("Shipping.1")}</div>
                    <div>{cart.shippingPrice.toFixed(2)}ALL</div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div>
                      <strong> {t("Order Total.1")}</strong>
                    </div>
                    <div>
                      <strong>{(cart.itemsPrice + cart.shippingPrice).toFixed(2)}ALL</strong>
                    </div>
                  </div>
                </li>
                {/* <li>
                      <div className="row">
                        <div>Tax</div>
                        <div>{cart.taxPrice.toFixed(2)}ALL</div>
                      </div>
                    </li>  */}
                <li>
                  <div className="row">
                    <div style={{ width: "100%" }}>
                      <button
                        type="button"
                        onClick={placeOrderHandler}
                        className="primary block"
                        disabled={cart.cartItems.length === 0}
                      >
                        {t("Place Order.1")}
                      </button>
                    </div>
                  </div>
                </li>
                {loading && <LoadingBox></LoadingBox>}
                {error && <MessageBox variant="danger">{error}</MessageBox>}
              </ul>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "150px" }}>
          <Footer></Footer>
        </div>
      </div>
    </Fragment >
  );
}