import React from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
const HomeAboutText = () => {
    const { t } = useTranslation();
    return (
        <div id="about-text" className="wrapper">
            <div className="row gutter-width-md with-pb-md">
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    <div className="description-2">
                        <h2>{t('Choose our Dental Depot.1')}</h2>
                    </div>

                    {/* <div className="signature spacer m-top-lg"> */}
                        {/* <div className="signature-img">
                            <img src="assets/img/demo/02_img.png" alt="Signature" />
                        </div> */}

                        {/* <div className="signature-content">
                            <h5 className="signature-name">Endri Dogjani</h5>
                            <p className="signature-position">Head Dentist</p>
                        </div> */}
                    {/* </div> */}
                </div>

                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <div className="description-2">
                        <p>{t('Prodental shpk comes with.1')}</p>
                    </div>

                    <div className="description-2 spacer p-top-lg">
                        <p>{t('The company is focused.1')}</p>
                    </div>

                    {/* <div className="button spacer p-top-lg">
                        <Link to="services" href="#services" className="btn btn-primary scroll-to-id">{t("Our Services.1")}</Link>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default HomeAboutText;
