import React from 'react';

import Accordion1 from '../services/Accordion1';
import Accordion2 from '../services/Accordion2';
import { useTranslation } from 'react-i18next';
const HomeServices = () => {
    const { t } = useTranslation();
    return (
        <section id="services" className="block bg-gray-light spacer p-top-xl p-bottom-xl" style={{ backgroundColor: "#566491" }}>
            <div className="wrapper">
                <div className="title text-max-width">
                    <h2 className='our_service'>{t('Our Services.1')}</h2>
                </div>
                <div className="description">
                    <p className='our_service'>{t('We Offer A Wide Range Of Dental Services.1')}</p>
                </div>

                <div className="row gutter-width-md with-pb-xs spacer m-top-xs">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <Accordion1 />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <Accordion2 />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeServices;
