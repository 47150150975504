import Axios from 'axios';
import {
    OFFERS_CREATE_FAIL,
    OFFERS_CREATE_REQUEST,
    OFFERS_CREATE_SUCCESS,
    OFFERS_LIST_REQUEST,
    OFFERS_LIST_SUCCESS,
    OFFERS_LIST_FAIL,
    OFFERS_DELETE_REQUEST,
    OFFERS_DELETE_SUCCESS,
    OFFERS_DELETE_FAIL,
    OFFERS_UPDATE_REQUEST,
    OFFERS_UPDATE_SUCCESS,
    OFFERS_UPDATE_FAIL
} from '../constants/offersConstant';

export const createOffers = (offers) => async (dispatch, getState) => {
    dispatch({ type: OFFERS_CREATE_REQUEST, payload: offers });
    try {
        const {
            userSignin: { userInfo }
        } = getState();
        const { data } = await Axios.post("/api/offers", offers, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        });
        dispatch({ type: OFFERS_CREATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: OFFERS_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};
export const listOffers = () => async (dispatch) => {
    dispatch({
        type: OFFERS_LIST_REQUEST,
    });
    try {
        const { data } = await Axios.get("/api/offers");
        dispatch({ type: OFFERS_LIST_SUCCESS, payload: data });
        localStorage.setItem('offers', JSON.stringify(data));
    } catch (error) {
        dispatch({ type: OFFERS_LIST_FAIL, payload: error.message });
    }
};
export const deleteOffers = (offerId) => async (dispatch, getState) => {
    dispatch({ type: OFFERS_DELETE_REQUEST, payload: offerId });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        const { data } = await Axios.delete(`/api/offers/${offerId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: OFFERS_DELETE_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: OFFERS_DELETE_FAIL, payload: message });
    }
};
export const updateOffer = (offer) => async (dispatch, getState) => {
    dispatch({ type: OFFERS_UPDATE_REQUEST, payload: offer });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        const { data } = await Axios.put(`/api/offers/${offer.id}`, offer, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: OFFERS_UPDATE_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: OFFERS_UPDATE_FAIL, payload: message });
    }
};
