import React, { useRef, Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from 'react-redux';
import { listOrderMine } from '../actions/orderAction';
import LoadingBox from '../components/loadmore/LoadMore';
import MessageBox from '../components/MessageBox';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from "react-highlight-words";
export default function OrderHistoryScreen() {
  const navigate = useNavigate();
  const orderMineList = useSelector((state) => state.orderMineList);
  const { loading, error, orders } = orderMineList;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listOrderMine());
  }, [dispatch]);
  const { t, i18n } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  let data = [];
  if (orders !== undefined) {
    Object.keys(orders).map((key) => {
      let obj = {
        key: key,
        id: orders !== undefined ? orders[key] !== undefined ? orders[key]._id : "-" : "-",
        date: orders !== undefined ? orders[key] !== undefined ? orders[key].createdAt : "-" : "-",
        total: orders !== undefined ? orders[key] !== undefined ? orders[key].totalPrice : "-" : "-",
        paid: orders !== undefined ? orders[key] !== undefined ? JSON.stringify(orders[key].isPaid) : "-" : "-",
        delivered: orders !== undefined ? orders[key] !== undefined ? JSON.stringify(orders[key].isDelivered) : "-" : "-",
        actions: <Button
          type="button"
          className="small"
          onClick={() => {
            navigate(`/order/${orders[key]._id}`);
          }}
          style={{
            fontSize: '15px',
            fontWeight: '600',
            color: '#006e7f',
          }}>
          {t("Details.1")}
        </Button>,
      }
      data.push(obj);
    })
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '30%',
      ...getColumnSearchProps('id'),
    },
    {
      title: t('DATE.1'),
      dataIndex: 'date',
      width: '20%',
      key: 'date',
      ...getColumnSearchProps('date'),
      sorter: (a, b) => Number(new Date(a.date)) - Number(new Date(b.date)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('TOTAL.1'),
      dataIndex: 'total',
      key: 'total',
      width: '20%',
      ...getColumnSearchProps('total'),
      sorter: (a, b) => a.total - b.total,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('PAID.1'),
      dataIndex: 'paid',
      key: 'paid',
      width: '20%',
      ...getColumnSearchProps('paid'),
      sorter: (a, b) => a.paid.length - b.paid.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('DELIVERED.1'),
      dataIndex: 'delivered',
      key: 'delivered',
      width: '20%',
      ...getColumnSearchProps('delivered'),
      sorter: (a, b) => a.delivered.length - b.delivered.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('ACTIONS.1'),
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      ...getColumnSearchProps('actions'),
    },
  ];
  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>
      <div>
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <div style={{ overflowX: "auto", marginTop: "5%" }}>
            <Table columns={columns} dataSource={data} />
          </div>
        )}
      </div>
    </Fragment>
  );
}