import React, { Component, useState } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

function ContactForm() {
    const Result = () => {
        return (
            <p>Your message has been successfully sent. I will contact you soon.</p>
        )
    }
    const [result, showResult] = useState(false);
    const sendEmail = async e => {
        e.preventDefault();
        emailjs.send("service_25h311w", "template_bo1278r", {
            name: e.target.name.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            question: e.target.question.value,
        }, "aReESUg2KqQUmjHUt")
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
        showResult(true);
    };
    setTimeout(() => {
        showResult(false);
    })
    const { t, i18n } = useTranslation();

    return (
        <form onSubmit={sendEmail} action="" id="cf-1" className="contact-form">
            <div className="form-group form-group-xs">
                <p className="input-group gutter-width-md no-space">
                    <span className="gutter-width">
                        <input name="name"
                            type="text"
                            className="form-control form-lg bg-white"
                            id="name"
                            placeholder={t("Name.1")}
                            required="required" />
                    </span>

                    <span className="gutter-width">
                        <input name="phone"
                            type="text"
                            className="form-control form-lg bg-white"
                            id="phone"
                            placeholder={t("Phone.1")}
                            required="required" />
                    </span>
                </p>
            </div>

            <div className="form-group form-group-xs">
                <p className="input-group gutter-width-md no-space">
                    <span className="gutter-width">
                        <input name="email"
                            type="email"
                            className="form-control form-lg bg-white"
                            id="email"
                            placeholder="Email"
                            required="required" />
                    </span>

                    <span className="gutter-width">
                        <input name="question"
                            type="text"
                            className="form-control form-lg bg-white"
                            id="cf-1-question"
                            placeholder={t("Question.1")}
                            required="required" />
                    </span>
                </p>
            </div>

            <div className="form-group form-group-xs mb-0">
                <button style={{ backgroundColor: "white", color: "#6c6c6c" }} type="submit" className="btn btn-primary">{t('Submit.1')}</button>
                <div className="row">{result ? <Result></Result> : null}</div>
            </div>
        </form>
    );
}

export default ContactForm;
