import React from 'react';
import ContactForm from '../../components/form/ContactForm';
import { useTranslation } from 'react-i18next';

const ContactsUs = () => {
    const { t } = useTranslation();
    return (
        <section id="contacts" style={{ backgroundColor: "rgb(86, 100, 145)" }} className="block bg-gray-light spacer m-top-xl p-top-xl p-bottom-xl">
            <div className="wrapper">
                <div className="title text-max-width-2">
                    <h2 className='contacts_title'>{t('Any Questions?.1')}</h2>
                </div>

                <div className="description text-max-width-2">
                    {/* <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium.</p> */}
                </div>

                <div className="contact-form-shortcode spacer p-top-lg">
                    <ContactForm />
                </div>
            </div>
        </section>
    );
};

export default ContactsUs;
