import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { savePaymentMethod } from '../actions/cartActions';
import CheckoutSteps from '../components/CheckoutSteps';
import { useNavigate } from 'react-router-dom'
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import { useTranslation } from 'react-i18next';
export default function PaymentMethodScreen() {
    const cart = useSelector((state) => state.cart);
    const navigate = useNavigate();
    const { shippingAddress } = cart;
    if (!shippingAddress.address) {
        navigate('/shipping')
    }
    const [paymentMethod, setPaymentMethod] = useState('');
    const dispatch = useDispatch();
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(savePaymentMethod("Cash"));
        navigate('/placeorder')
    };
    const { t } = useTranslation();
    return (
        <div>
            <Header />
            <CheckoutSteps step1 step2 step3></CheckoutSteps>
            <form className="form" onSubmit={submitHandler}>
                <div>
                    <h1>{t('Payment Method.1')}</h1>
                </div>
                {/* <div>
                    <div>
                        <input
                            type="radio"
                            id="paypal"
                            value="PayPal"
                            name="paymentMethod"
                            required
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        ></input>
                        <label htmlFor="paypal">PayPal</label>
                    </div>
                </div> */}
                <div>
                    <div>
                        <input
                            type="radio"
                            id="cash"
                            value="Cash"
                            name="paymentMethod"
                            required
                            onChange={(e) => setPaymentMethod(e.target.value)}
                            checked
                        ></input>
                        <label htmlFor="cash">Cash</label>
                    </div>
                    <label />
                    <button className="primary" type="submit">
                        {t('Continue.1')}
                    </button>
                </div>
            </form>
            <Footer></Footer>
        </div>
    );
}