import React from 'react';

const HomeAboutImg = () => {
    return (
        <div id="about-img" className="spacer p-top-xl">
            <div className="img-size-1">
                <div className="img object-fit">
                    <div className="object-fit-cover">
                        <img src="assets/img/placeholder/forniture-dentali-donati-3.jpeg" alt="IMG" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAboutImg;
