import React from 'react';
import ButtonSearch from '../../components/button/ButtonSearch';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const WidgetSearch = () => {
    const productList = useSelector((state) => state.productList);
    const { loading, error, products } = productList;
    const [searchValue, setSearchValue] = useState("");
    var id;
    Object.keys(products).map((key) => {
        if (searchValue == products[key].name) {
            id = products[key]._id;
        }
    })
    return (
        <div className="widget widget_search">
            <form className="search-form" role="search" method="get" action={process.env.PUBLIC_URL + "/product" + "/" + id}>
                <div className="search input-group">
                    <input className="form-control form-control-lg" type="text" placeholder="Search" onChange={event => { setSearchValue(event.target.value) }} />
                    <div className="input-group-append">
                        <button type="submit" className="btn btn-lg btn-link border-0 p-0 min-w-auto link-no-space"><i className="fas fa-search"></i></button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default WidgetSearch;
