import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import { detailsProduct, updateProduct } from '../actions/productActions';
import LoadingBox from '../components/loadmore/LoadMore';
import MessageBox from '../components/MessageBox';
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants';
import { useNavigate, useParams } from 'react-router-dom';
import moment from "moment-timezone";
import { Stack, Alert, AlertTitle } from '@mui/material';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, message, Space, DatePicker } from 'antd';
import { deleteUser, listUsers } from '../actions/userAction';
import { listCategories } from '../actions/categoriesAction';

export default function ProductEditScreen() {
  moment.tz.setDefault("Europe/Berlin");
  const { id } = useParams();
  const navigate = useNavigate();
  const productId = id;
  const { RangePicker } = DatePicker;
  var [name, setName] = useState('');
  var [price, setPrice] = useState('');
  var [image, setImage] = useState('');
  var [category, setCategory] = useState('');
  var [countInStock, setCountInStock] = useState('');
  var [brand, setBrand] = useState('');
  var [isOffer, setisOffer] = useState('');
  var [isUserOffer, setisUserOffer] = useState('');
  var [description, setDescription] = useState('');
  var [date, setDate] = useState(new Date());
  var [startDate, setStartDate] = useState(new Date(date));
  var [endDate, setEndDate] = useState(new Date(date));
  var [discount, setDiscount] = useState('');
  var [discountPrice, setDiscountPrice] = useState('');
  var productDetails = useSelector((state) => state.productDetails);
  var { loading, error, product } = productDetails;
  var productUpdate = useSelector((state) => state.productUpdate);
  const categoriesList = useSelector((state) => state.categoriesList);
  const { categories } = categoriesList;
  var {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;
  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listUsers());
    dispatch(listCategories());
    if (successUpdate) {
      navigate('/dashboard');
    }
    if (!product || product._id !== productId || successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      dispatch(detailsProduct(productId));
    } else {
      setName(product.name);
      setPrice(product.price);
      setImage(product.image);
      setCategory(product.category);
      setCountInStock(product.countInStock);
      setBrand(product.brand);
      setDescription(product.description);
      product.startDate !== null ?
        setStartDate(moment(product.startDate).format("YYYY-MM-DD HH:mm")) : setStartDate(moment(date).format("YYYY-MM-DD HH:mm"));
      product.endDate !== "" ?
        setEndDate(moment(product.endDate).format("YYYY-MM-DD HH:mm")) : setEndDate(new Date());
      setDiscount(product.discount);
      product.endDate >= moment(date).format("YYYY-MM-DD HH:mm") ? setDiscountPrice(product.discountPrice) : setDiscountPrice('');
      setisOffer(product.isOffer);
      setisUserOffer(product.isUserOffer);
    }
  }, [product, dispatch, productId, successUpdate, navigate]);
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProduct({
        _id: productId,
        name,
        price,
        discount,
        discountPrice,
        startDate,
        endDate,
        image,
        category,
        brand,
        countInStock,
        description,
        isOffer,
        isUserOffer
      })
    );
  };
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [errorUpload, setErrorUpload] = useState('');
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('image', file);
    setLoadingUpload(true);
    try {
      const { data } = await Axios.post('/api/uploads', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      const result = await Axios.get(`/api/uploads/${data}`);
      setImage(result.data.readStream)
      setLoadingUpload(false);
    } catch (error) {
      setErrorUpload(error.message);
      setLoadingUpload(false);
    }
  };
  const onClick = ({ key }) => {
    message.info(`Click on item ${key}`);
    setCategory(key)
  };

  let menus = users?.map((user) => {
    return (
      {
        key: user["email"],
        label: user["email"]
      }
    )
  });
  let categoriesMenus = categories?.map((category) => {
    return (
      {
        key: category.name,
        label: category.name
      }
    )
  });
  const menu = (
    <Menu
      onClick={onClick}
      items={[
        {
          key: '1',
          type: 'group',
          label: 'Categories',
          children: categoriesMenus
        },
        {
          key: '2',
          type: 'group',
          label: 'Users',
          children: menus
        },
      ]}
    />
  );
  const onChange = (value, dateString) => {
    const date1 = moment(date).format("YYYY-MM-DD HH:mm");
    const date2 = dateString[0];
    const date3 = dateString[1];
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
    if (date2 >= date1 && date1 < date3 && discount > 0 && price > 0) {
      discountPrice = Math.round(price - (price / 100) * discount);
    } else {
      discountPrice = '';
    }
  }
  const onOk = () => {
    setDiscountPrice(discountPrice)
  };
  return (
    <>
      {loadingUpdate && <LoadingBox></LoadingBox>}
      {/* {errorUpdate && <MessageBox variant="danger">{errorUpdate}</MessageBox>} */}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) :
        <Fragment>
          <Loading />
          <MetaTags>
            <meta charSet="UTF-8" />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta name="description" content="" />
            <meta name="keywords" content="" />
            <meta name="robots" content="index, follow, noodp" />
            <meta name="googlebot" content="index, follow" />
            <meta name="google" content="notranslate" />
            <meta name="format-detection" content="telephone=no" />
          </MetaTags>
          <Header />
          <div>
            <form className="form" onSubmit={submitHandler}>
              <div>
                <h1>Edit Product {productId}</h1>
              </div>
              {errorUpdate && (
                <MessageBox variant="danger">This product has been already created!</MessageBox>
              )}
              <div>
                <label htmlFor="name">Name</label>
                <input
                  name="name"
                  id="name"
                  type="text"
                  placeholder="Enter name"
                  value={name.startsWith('sample name') ? '' : name}
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>
              <div>
                <label htmlFor="price">Price</label>
                <input
                  id="price"
                  type="text"
                  placeholder="Enter price"
                  value={price === 0 ? "" : price}
                  onChange={(e) => setPrice(e.target.value)}
                ></input>
              </div>
              <div>
                <label htmlFor="image">Image</label>
                <input
                  id="image"
                  type="text"
                  placeholder="Enter image"
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                  accept="image/*"
                ></input>
              </div>
              <div>
                <label htmlFor="imageFile">Image File</label>
                <input
                  type="file"
                  id="imageFile"
                  label="Choose Image"
                  onChange={uploadFileHandler}
                ></input>
                {loadingUpload && <LoadingBox></LoadingBox>}
                {errorUpload && (
                  <MessageBox variant="danger">{errorUpload}</MessageBox>
                )}
              </div>
              <div>
                <Dropdown overlay={menu}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      Category OR Profits User
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
                <input
                  id="category"
                  type="text"
                  placeholder="Enter category"
                  value={category.startsWith('sample category') ? "" : category}
                ></input>
              </div>
              <div>
                <label htmlFor="brand">Brand</label>
                <input
                  id="brand"
                  type="text"
                  placeholder="Enter brand"
                  value={brand.startsWith('sample brand') ? "" : brand}
                  onChange={(e) => setBrand(e.target.value)}
                ></input>
              </div>
              <div>
                <label htmlFor="countInStock">Count In Stock</label>
                <input
                  id="countInStock"
                  type="text"
                  placeholder="Enter countInStock"
                  value={countInStock === 0 ? "" : countInStock}
                  onChange={(e) => setCountInStock(e.target.value)}
                ></input>
              </div>
              <div>
                <label >Discount -%</label>
                <input
                  id="discount"
                  type="text"
                  placeholder="Enter discount"
                  value={discount === 0 ? "" : discount}
                  onChange={(e) => setDiscount(e.target.value)}
                ></input>
              </div>
              <Space direction="vertical" size={12}>
                <RangePicker
                  showTime={{
                    format: 'HH:mm',
                  }}
                  defaultValue={[moment(product.startDate !== null ? product.startDate : date), moment(product.endDate !== '' ? product.endDate : date)]}
                  format="YYYY-MM-DD HH:mm"
                  accept="image/*"
                  onChange={onChange}
                  onOk={onOk}
                />
              </Space>
              <div>
                <label>Discount Price</label>
                <input
                  id="discountPrice"
                  placeholder="Discount Price"
                  readOnly
                  value={discountPrice === 0 ? "" : discountPrice}
                ></input>
              </div>
              <div>
                <label htmlFor="isOffer">Is Offer</label>
                <input
                  name="isOffer"
                  id="isOffer"
                  type="checkbox"
                  checked={isOffer}
                  onChange={(e) => setisOffer(e.target.checked)}
                ></input>
                <label htmlFor="isUserOffer">Is User Offer</label>
                <input
                  name="isUserOffer"
                  id="isUserOffer"
                  type="checkbox"
                  checked={isUserOffer}
                  onChange={(e) => setisUserOffer(e.target.checked)}
                ></input>
              </div>
              <div>
                <label htmlFor="description">Description</label>
                <textarea
                  id="description"
                  rows="3"
                  type="text"
                  placeholder="Enter description"
                  value={description.startsWith('sample description') ? "" : description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
              <div>
                <label></label>
                <button className="primary" type="submit">
                  Update
                </button>
              </div>
            </form>
            <div style={{ marginTop: "150px" }}>
              <Footer></Footer>
            </div>
          </div>
        </Fragment>
      }
    </>
  );
}
