import React from 'react';

import ClientsData from '../../data/clients/clientsData';

const HomeAboutClients = () => {
    return (
        <div id="clients">
            <div className="wrapper">
                <div className="clients">
                    <ol>
                        <div className="clients-items">
                            {ClientsData && ClientsData.slice(0, 8).map((item, key) => {
                                return (
                                    <div key={key} className="clients-item">
                                        <div className="clients-item-content" id="clients-item-content">
                                            <img src={item.imgSrc} alt={item.alt} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </ol>
                    <ol>
                        <div className="clients-items">
                            {ClientsData && ClientsData.slice(8).map((item, key) => {
                                return (
                                    <div key={key} className="clients-item">
                                        <div className="clients-item-content" id="clients-item-content">
                                            <img src={item.imgSrc} alt={item.alt} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </ol>
                </div>
            </div>
        </div>
    );
};

export default HomeAboutClients;
