import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import MessageBox from "../components/MessageBox";
import { detailsProduct } from "../actions/productActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import moment from "moment";
const NewsSinglePost = () => {
  document.body.classList.add("single-post");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const [qty, setQty] = useState(1);
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;
  const cart = useSelector((state) => state.cart);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  var str = location.pathname;
  var n = str.lastIndexOf("/");
  var result = str.substring(n + 1);
  var id;
  var name;
  var price;
  var description;
  var countInStock;
  var image;
  var discountPrice;
  var endDate;
  var startDate;
  var discount;
  var isOffer;
  var isUserOffer;
  var categories;
  var brand;
  var [date, setDate] = useState(new Date());
  const { Option } = Select;
  const redirect = location.search
    ? location.search.split("=")[1]
    : "/";
  if (product !== undefined) {
    if (result == product._id) {
      id = product._id;
      name = product.name;
      price = product.price;
      description = product.description;
      countInStock = product.countInStock;
      image = product.image;
      discount = product.discount;
      discountPrice = product.discountPrice;
      endDate = product.endDate;
      startDate = product.startDate;
      isOffer = product.isOffer;
      isUserOffer = product.isUserOffer;
      categories = product.category
      brand = product.brand
    }
  }
  useEffect(() => {
    dispatch(detailsProduct(result));
  }, [dispatch, result]);
  const addToCartHandler = (qty) => {
    navigate(`/cart/${product._id}?qty=${qty}`);
  };
  let stock = [];
  for (var i = 0; i <= countInStock; i++) {
    stock.push(i);
  }
  var dateInitial = moment(date).format("YYYY-MM-DD HH:mm");
  var endDate = moment(endDate).format("YYYY-MM-DD HH:mm");
  var startDate = moment(startDate).format("YYYY-MM-DD HH:mm");
  const signinFirst = () => {
    navigate(`/signin?redirect=/product/${id}`);
  };
  const { t, i18n } = useTranslation();
  const setValue = (value) => {
    setQty(value)
  }
  return (
    <div>
      {loading ? (
        <Loading></Loading>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Fragment>
          <MetaTags>
            <meta charSet="UTF-8" />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta name="description" content="" />
            <meta name="keywords" content="" />
            <meta name="robots" content="index, follow, noodp" />
            <meta name="googlebot" content="index, follow" />
            <meta name="google" content="notranslate" />
            <meta name="format-detection" content="telephone=no" />
          </MetaTags>
          <Loading />
          <Header />
          <main id="main" className="site-main">
            <div id="page-content" style={{ backgroundColor: "#F8F8F8" }}>
              <div className="product_background" >
                <div className="overlay_product_background"></div>
                <div className="container_product_background">
                  <h2 className="shop_label">
                    SHOP DETAILS
                  </h2>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div><a href="/">Home</a></div>
                    <div style={{ marginLeft: "10px" }}>{">"}</div>
                    <div style={{ marginLeft: "10px", color: "white" }}>Shop Details</div>
                  </div>
                </div>
              </div>
              <div className="wrapper" style={{ marginTop: "10%" }}>
                <div className="content">
                  <div className="row " >
                    <div class="columnSingleItem" style={{ width: "50%" }}>
                      {endDate != undefined ?
                        dateInitial >= startDate && dateInitial < endDate && discount > 0 && isOffer == false && isUserOffer == false ?
                          <div className="price" style={{ marginLeft: "9%", position: "absolute", zIndex: "1", marginTop: "7%", fontSize: "60px", fontFamily: "cursive", color: "#48D1CC" }}>-{discount}<img src="/assets/img/placeholder/discount.png" style={{ width: "100px" }}></img></div> : <></> : <></>}
                      <img src={image} alt={name} style={{ width: "97%", height: "64%", objectFit: "contain" }} />
                    </div>
                    <div class="columnSingleItem" style={{ width: "50%" }}>
                      <div
                        className="card card-body product_description"
                        style={{
                          width: "80%",
                          marginLeft: "-15%",
                          marginTop: "-2%"
                        }}
                      >
                        <div className="product_info">
                          <div className="product_price">
                            <div><h6 style={{ fontSize: "26px" }}>{name}</h6></div>
                          </div>
                          {userInfo !== null ?
                            dateInitial >= startDate && dateInitial < endDate && discount > 0 && isOffer == false && isUserOffer == false ?
                              <div>
                                <div className="price">{discountPrice}ALL</div>
                                <s className="price" style={{ color: "lightgrey", fontSize: "27px" }}>{price}ALL</s>
                              </div> : <div className="price">{price}ALL</div>
                            : (
                              <a onClick={() => signinFirst()}>
                                <FontAwesomeIcon
                                  icon={faLock}
                                ></FontAwesomeIcon>
                              </a>
                            )}
                          <div> <p style={{
                            fontSize: "16px",
                            lineHeight: "1.8",
                            marginBottom: "10px",
                            fontFamily: "Montserrat,sans-serif",
                            color: "black",
                            marginBottom: "10%"
                          }}>{description}</p></div>
                          <div className="row" style={{
                            display: "flex",
                            justifyContent: "flex-start"
                          }}>
                            <div style={{
                              color: "black",
                              fontWeight: "700",
                              marginBottom: "4%"
                            }}>Avability:</div>
                            <div>
                              {countInStock > 0 ? (
                                <span className="success">{t('In Stock.1')}</span>
                              ) : (
                                <span className="danger">
                                  {t('Unavailable.1')}
                                </span>
                              )}
                            </div>
                          </div>
                          {countInStock > 0 && (
                            <div style={{ display: "flex", marginBottom: "4%" }}>
                              <div className="row">
                                <div style={{
                                  color: "black",
                                  fontWeight: "700"
                                }}>Qty:</div>
                                <div style={{ marginLeft: "16px" }}>
                                  <Select
                                    value={qty}
                                    onChange={setValue}
                                  >
                                    {stock.map((x) => (
                                      <Option key={x + 1} value={x + 1}>
                                        {x + 1}
                                      </Option>
                                    ))}
                                  </Select>
                                </div>
                              </div>
                            </div>
                          )}
                          <div style={{ display: "flex", marginBottom: "4%" }}>
                            <div style={{
                              color: "black",
                              fontWeight: "700",
                            }} >Categories:</div>
                            <div style={{ marginLeft: "16px" }} >{categories}</div>
                          </div>
                          <div style={{ display: "flex", marginBottom: "4%" }}>
                            <div style={{
                              color: "black",
                              fontWeight: "700",
                            }} >Brand:</div>
                            <div style={{ marginLeft: "16px" }} >{brand}</div>
                          </div>
                          <button
                            onClick={() => addToCartHandler(qty)}
                            className="primary block addCartButton"
                          >
                            {t('AddtoCart.1')}
                          </button>
                        </div>
                        {/* </li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <div >
            <Footer></Footer>
          </div>
        </Fragment>
      )
      }
    </div >
  );
};
export default NewsSinglePost;
