import React from 'react';
import { Link } from 'react-scroll';
import { useDispatch, useSelector } from "react-redux";
import { signout } from '../../actions/userAction';
import OrderHistoryScreen from '../../pages/OrderHistoryScreen';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import { useNavigate } from 'react-router-dom';

const LangSelector = () => {
    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const signoutHandler = () => {
        dispatch(signout());
        window.location.reload();
    }
    const handleClick = (lang) => {
        i18n.changeLanguage(lang);
    }
    const navigate = useNavigate();
    var badgeContent = localStorage.getItem('cartItems');
    return (
        <>
            {isMobile ?
                <nav className="languages">
                    <ul className="nav ">
                        <li className="lang-item">
                            <a title="En" onClick={() => handleClick('en')}>
                                <h2 className='lang'>EN</h2>
                            </a>
                        </li>
                        <li className="lang-item">
                            <a title="Al" onClick={() => handleClick('al')}>
                                <h2 className='lang'>AL</h2>
                            </a>
                        </li>
                    </ul>
                </nav> : <nav className="languages">
                    <ul className="nav ">

                        {userInfo ?
                            (
                                <li className="userInfo">
                                    <div className="dropdown">
                                        <Link to="#" >
                                            {userInfo.name}
                                            <i className="fa fa-caret-down"></i>
                                        </Link>
                                        <ul className="dropdown-content">
                                            <li>
                                                <a href={process.env.PUBLIC_URL + "/dashboard"}>Dashboard</a>
                                            </li>
                                            <li>
                                                <Link to="#signout" onClick={signoutHandler}>
                                                    {t("Sign Out.1")}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div></li>

                            ) :
                            (
                                <li className="user">
                                    <div id="signin" className="nav-item">
                                        <a to="signin" title="Signin" href="/signin" >{t("Signin.1")}</a>
                                    </div>
                                </li>)
                        }
                        <li className="lang-item">
                            <a title="En" onClick={() => handleClick('en')}>
                                EN
                            </a>
                        </li>

                        <li className="lang-item">
                            <a title="Al" onClick={() => handleClick('al')}>
                                AL
                            </a>
                        </li>
                    </ul>
                </nav>
            }
        </>


    );
};

export default LangSelector;
