import React from 'react';
import ButtonSearch from '../button/ButtonSearch';
import { useState } from "react";
import { useSelector } from "react-redux";
import MessageBox from '../MessageBox';
import { useNavigate } from 'react-router-dom';

const SearchForm = () => {
    const productList = useSelector((state) => state.productList);
    var { products } = productList;
    const [searchValue, setSearchValue] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    var id;
    var nameUrl;
    var productCateg = ["Endodonti", "Dentistry Restorative", "Cemente dhe shtresa izoluese", "Materiale Mase", "Profilaksi", "Te tjera"]
    var productListing = localStorage.getItem('products');
    products = JSON.parse(productListing);
    const searchButton = (event) => {
        event.preventDefault();
        if (id !== undefined) {
            navigate(process.env.PUBLIC_URL + "/product" + "/" + id);
            window.location.reload();
        } else if (nameUrl != undefined) {
            navigate(nameUrl);
            window.location.reload();
        } else {
            setErrorMessage("No result !")
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (id !== undefined) {
                navigate(process.env.PUBLIC_URL + "/product" + "/" + id);
                window.location.reload();
            } else if (nameUrl != undefined) {
                navigate(nameUrl);
                window.location.reload();
            } else {
                setErrorMessage("No result !")
            }
        }
    }
    return (
        <form className="search-form" role="search">
            <MessageBox variant="danger">{errorMessage}</MessageBox>
            <div className="search input-group">
                <input className="form-control form-control-lg" type="text" placeholder="Search" onKeyDown={handleKeyDown} onChange={event => { setSearchValue(event.target.value) }} />
                <div className="input-group-append">
                    <ButtonSearch searchButton={searchButton}></ButtonSearch>
                </div>
            </div>
            <div className='products_result'>
                {
                    products.filter(post => {
                        if (searchValue === '') {
                            return post;
                        } else if (post.name.toLowerCase().includes(searchValue.toLowerCase())) {
                            return post;
                        }
                    }).map((post, index) => (
                        searchValue !== '' ?
                            <div className="form-control form-control-lg" key={index}>
                                {Object.keys(productCateg).map((key) => (
                                    productCateg[key] === post.category ?
                                        <ul>
                                            <li style={{ backgroundColor: "#f0f0f0", padding: "20px", color: "#006e7f", fontWeight: "bold" }}>{post.category}</li>
                                            <li style={{ color: "#006e7f", padding: "20px" }} >
                                                <a href={process.env.PUBLIC_URL + "/products/" + post.category}>{post.name}</a>
                                            </li>
                                        </ul> : <></>
                                ))}
                            </div> : <></>
                    ))
                }
            </div>
        </form>
    );
};

export default SearchForm;
