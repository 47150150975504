import React from 'react';

import WidgetSearch from './WidgetSearch';
import WidgetCategories from './WidgetCategories';
import WidgetRecentEntries from './WidgetRecentEntries'
import WidgetComments from './WidgetComments';
import WidgetTagCloud from './WidgetTagCloud';

const Widget = () => {
    return (
        <aside id="aside" className="widget-area">
            <WidgetSearch />

            <WidgetCategories />

            <WidgetRecentEntries />

            <WidgetComments />

            <WidgetTagCloud />
        </aside>
    );
};

export default Widget;
