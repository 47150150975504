import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import {
  productListReducer,
  productDetailsReducer,
  productCreateReducer,
  productUpdateReducer,
  productDeleteReducer,
} from "./reducers/productReducers";
import {
  categoriesListReducer,
  categoriesDetailsReducer,
  categoriesCreateReducer,
  categoriesUpdateReducer,
  categoriesDeleteReducer,
} from "./reducers/categoriesReducers";
import { cartReducer } from "./reducers/cartReducers";
import { userSigninReducer, userRegisterReducer, userDetailsReducer, userUpdateProfileReducer, userListReducer, userUpdateReducer, userAddressMapReducer, userResetReducer } from "./reducers/userReducers";
import { orderCreateReducer, orderDeliverReducer, orderDetailsReducer, orderListReducer, orderMineListReducer, orderSummaryReducer, orderPayReducer } from "./reducers/orderReducer";
import { offersCreateReducer, offersListReducer, offersDeleteReducer, offersUpdateReducer } from "./reducers/offersReducer";
const initialState = {
  userSignin: {
    userInfo: localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : null,
  },
  cart: {
    cartItems: localStorage.getItem('cartItems')
      ? JSON.parse(localStorage.getItem('cartItems'))
      : [],
    shippingAddress: localStorage.getItem('shippingAddress')
      ? JSON.parse(localStorage.getItem('shippingAddress'))
      : {},
  },
  products: {
    products: localStorage.getItem('products')
      ? JSON.parse(localStorage.getItem('products'))
      : [],
  },
};
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      name: "MyApp",
      actionsBlacklist: ["REDUX_STORAGE_SAVE"],
    })
    : compose;
// const enhancer = composeEnhancers(applyMiddleware(thunk));
const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  cart: cartReducer,
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderMineList: orderMineListReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productDelete: productDeleteReducer,
  orderList: orderListReducer,
  orderDelete: orderDetailsReducer,
  orderDeliver: orderDeliverReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDetailsReducer,
  userUpdate: userUpdateReducer,
  orderSummary: orderSummaryReducer,
  orderPay: orderPayReducer,
  userAddressMap: userAddressMapReducer,
  userReset: userResetReducer,
  offersCreate: offersCreateReducer,
  offerList: offersListReducer,
  offerDelete: offersDeleteReducer,
  offerUpdate: offersUpdateReducer,
  categoriesList: categoriesListReducer,
  categoriesCreate: categoriesCreateReducer,
  categoriesUpdate: categoriesUpdateReducer,
  categoriesDelete: categoriesDeleteReducer,
});
const store = createStore(
  reducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk))
);
export default store;
