import Axios from 'axios';
import { PayPalButton } from 'react-paypal-button-v2';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deliverOrder, detailsOrder, payOrder } from '../actions/orderAction';
import LoadingBox from '../blocks/loading/Loading';
import MessageBox from '../components/MessageBox';
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import {
  ORDER_DELIVER_RESET,
  ORDER_PAY_RESET,
} from '../constants/orderConstants';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import jwt from 'jwt-decode';
import { signout } from '../actions/userAction';
import StripeContainer from './stripe/StripeContainer';
import { WindowsFilled } from '@ant-design/icons';
import moment from 'moment';
export default function OrderScreen(props) {
  const params = useParams();
  const { id: orderId } = params;
  const navigate = useNavigate();
  const [sdkReady, setSdkReady] = useState(false);
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const orderPay = useSelector((state) => state.orderPay);
  const {
    loading: loadingPay,
    error: errorPay,
    success: successPay,
  } = orderPay;
  const orderDeliver = useSelector((state) => state.orderDeliver);
  const {
    loading: loadingDeliver,
    error: errorDeliver,
    success: successDeliver,
  } = orderDeliver;
  if (userInfo == null) {
    navigate("/signin")
  }
  const dispatch = useDispatch();
  useEffect(() => {
    if (userInfo) {
      const token = jwt(userInfo.token);
      if (token.exp * 1000 < Date.now()) {
        dispatch(signout());
        window.location.reload();
      }
    }
    const addPayPalScript = async () => {
      const { data } = await Axios.get('/api/config/paypal');
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.paypal.com/sdk/js?client-id=${data}`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };
    if (
      !order ||
      successPay ||
      successDeliver ||
      (order && order._id !== orderId)
    ) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(detailsOrder(orderId));
    } else {
      if (!order.isPaid) {
        if (!window.paypal) {
          addPayPalScript();
        } else {
          setSdkReady(true);
        }
      }
    }
  }, [dispatch, orderId, sdkReady, successPay, successDeliver, order]);
  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(order, paymentResult));
  };
  const deliverHandler = () => {
    dispatch(deliverOrder(order._id));
    window.location.reload();
  };
  const { t, i18n } = useTranslation();
  const cashPayment = (paymentResult) => {
    dispatch(payOrder(order, paymentResult));
    window.location.reload();
  }
  const printReceipt = () => {
    window.print();
  }
  const paid = new Date(order?.paidAt).toLocaleString();
  const delivered = new Date(order?.deliveredAt).toLocaleString();
  var [date, setDate] = useState(new Date());
  var dateInitial = moment(date).format("YYYY-MM-DD HH:mm");

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <>
      <Header />
      <div>
        <h1>{t("Order.1")} {order._id}</h1>
        <div className="row top">
          <div className="col-2">
            <ul>
              <li>
                <div className="card card-body">
                  <h2>{t("Shipping.1")}</h2>
                  <p>
                    <strong>{t("Name.1")}:</strong> {order.shippingAddress.fullName} <br />
                    <strong>{t("Address.1")}: </strong> {order.shippingAddress.address},
                    {order.shippingAddress.city},
                    {order.shippingAddress.postalCode},
                    {order.shippingAddress.country}
                  </p>
                  {order.isDelivered ? (
                    <MessageBox variant="success">
                      {t("Delivered.1")} {delivered}
                    </MessageBox>
                  ) : (
                    <MessageBox variant="danger">{t("NotDelivered.1")}</MessageBox>
                  )}
                </div>
              </li>
              <li>
                <div className="card card-body">
                  <h2>{t("Payment.1")}</h2>
                  <p>
                    <strong>{t("Method.1")}</strong> {order.paymentMethod}
                  </p>
                  {order.isPaid ? (
                    <MessageBox variant="success">
                      {t("Paid.1")}{paid}
                    </MessageBox>
                  ) : (
                    <MessageBox variant="danger">{t("NotPaid.1")}</MessageBox>
                  )}
                </div>
              </li>
              <li>
                <div className="card card-body">
                  <h2>{t("Order Items.1")}</h2>
                  <ul>
                    {order.orderItems.map((item) => (
                      <li key={item.product}>
                        <div className="row" style={{ alignItems: "center" }}>
                          <div>
                            <img
                              src={item.image}
                              alt={item.name}
                              className="small"
                            ></img>
                          </div>
                          <div >
                            <Link to={`/product/${item.product}`}>
                              {item.name}
                            </Link>
                          </div>
                          <div >
                            <div>
                              {
                                dateInitial >= moment(item.startDate).format("YYYY-MM-DD HH:mm") && dateInitial < moment(item.endDate).format("YYYY-MM-DD HH:mm") && item.discountPrice > 0 ?
                                  <div>
                                    {item.qty} x {item.discountPrice} = {item.qty * item.discountPrice}ALL
                                  </div>
                                  :
                                  <div>
                                    {item.qty} x {item.price} = {item.qty * item.price}ALL
                                  </div>
                              }
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-1">
            <div className="card card-body">
              <ul>
                <li>
                  <h2>{t("Order Summary.1")}</h2>
                </li>
                <li>
                  <div className="row">
                    <div>{t("items.1")}</div>
                    <div>{order.itemsPrice.toFixed(2)}ALL</div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div>{t("Shipping.1")}</div>
                    <div>{order.shippingPrice.toFixed(2)}ALL</div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div>
                      <strong> {t("Order Total.1")}</strong>
                    </div>
                    <div>
                      <strong>{(order.totalPrice + order.shippingPrice).toFixed(2)}ALL</strong>
                    </div>
                  </div>
                </li>
                {/* <li>
                      <div className="row">
                        <div>Tax</div>
                        <div>{cart.taxPrice.toFixed(2)}ALL</div>
                      </div>
                    </li>  */}
                {order.isPaid == true ?
                  <button class="hide-on-print" onClick={printReceipt}>Print</button> : <></>}
                {!order.isPaid && (
                  <li>
                    {!sdkReady ? (
                      <LoadingBox></LoadingBox>
                    ) : (
                      <>
                        {errorPay && (
                          <MessageBox variant="danger">{errorPay}</MessageBox>
                        )}
                        {loadingPay && <LoadingBox></LoadingBox>}
                        {/* <PayPalButton
                          amount={order.totalPrice}
                          onSuccess={successPaymentHandler}
                        ></PayPalButton> */}
                        {/* <StripeContainer order={order} userInfo={userInfo}></StripeContainer> */}
                      </>
                    )}
                  </li>
                )}
                {userInfo !== null ?
                  userInfo.isAdmin && !order.isDelivered && (
                    <li>
                      {loadingDeliver && <LoadingBox></LoadingBox>}
                      {errorDeliver && (
                        <MessageBox variant="danger">{errorDeliver}</MessageBox>
                      )}
                      <button
                        type="button"
                        className="primary block"
                        onClick={deliverHandler}
                      >
                        {t("Deliver Order.1")}
                      </button>
                    </li>
                  ) : <></>}
                {userInfo !== null ?
                  userInfo.isAdmin && !order.isPaid && (
                    <li>
                      {loadingDeliver && <LoadingBox></LoadingBox>}
                      {errorDeliver && (
                        <MessageBox variant="danger">{errorDeliver}</MessageBox>
                      )}
                      <button
                        type="button"
                        className="primary block"
                        amount={order.totalPrice}
                        onClick={cashPayment}
                      >
                        {t("It is payed.1")}
                      </button>
                    </li>
                  ) : <></>}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}