import React from 'react';
import { useTranslation } from 'react-i18next';

const PageTitleNews = () => {
    const { t, i18n } = useTranslation();
    return (
        <div id="page-title">
            <div className="wrapper">
                <div className="title">
                    <h1 className="large">{t('Our Products.1')}</h1>
                </div>
                <div className="description spacer p-top-lg text-max-width-2">
                    <p>{t('Technology, innovation and strength.1')}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PageTitleNews;
