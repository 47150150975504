import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';

import HeaderLogo from '../../blocks/logo/HeaderLogo';
import SearchForm from '../form/SearchForm';
import LangSelector from '../language/LangSelector';

class SearchModal extends Component {
    constructor(context) {
        super(context);

        this.state = {
            showModal: false
        };

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    };

    open() {
        this.setState({ showModal: true }, function () {
        });
    };

    close() {
        this.setState({ showModal: false });
    };

    render() {
        return (
            <Fragment>
                <div className="search-toggle">
                    <button onClick={this.open} type="button" className="btn btn-primary btn-square min-w-auto" data-toggle="modal" data-target="#search-modal"><i className="fas fa-search i-default"></i></button>
                </div>
                <Modal className="modal fade"
                    id="search-modal"
                    show={this.state.showModal}
                    onHide={this.close}
                    backdrop={false}
                    aria-labelledby="search-modal"
                    aria-hidden="true"
                    dialogClassName="modal-full"
                    ref={node => this.chart = node}
                >
                    <div className="wrapper">
                        <div className="modal-content">
                            <div className="modal-header modal-header-top">
                                <div className="header-content d-flex justify-content-between w-100">
                                    <div className="header-left align-self-center">
                                        <div className="d-flex align-items-center">
                                            <HeaderLogo />
                                        </div>
                                    </div>
                                    <div className="header-right d-flex justify-content-end">
                                        <div className="d-flex align-items-center">
                                            <div className="search-close-toggle">
                                                <button onClick={this.close} type="button" className="btn btn-primary btn-square min-w-auto" data-dismiss="modal" aria-label="Close"><i className="fas fa-times i-default"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body modal-body-centered">
                                <SearchForm />
                            </div>
                        </div>
                    </div>
                </Modal>
            </Fragment>
        );
    };
};

export default SearchModal;
