import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { useDispatch, useSelector } from "react-redux";
import { signout } from '../../actions/userAction';
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import { useNavigate } from 'react-router-dom';
import LangSelector from '../../components/language/LangSelector';

const ModalMenuPrimary = (props) => {
  const { t, i18n } = useTranslation();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  const signoutHandler = () => {
    dispatch(signout());
    window.location.reload();
  }
  const navigate = useNavigate();
  var badgeContent = localStorage.getItem('cartItems');
  return (
    <nav className="menu-primary">
      <ul className="nav flex-column">
        {userInfo ?
          <li className="nav-item">
            <Badge color="secondary" style={{ color: "white" }} badgeContent={JSON.parse(badgeContent) !== null ? JSON.parse(badgeContent).length : 0}>
              <h3>Cart</h3>
              <ShoppingCartIcon style={{ color: "#273b4a", marginLeft: "10px" }} onClick={() => navigate(`/cart`)}>
              </ShoppingCartIcon>
            </Badge>
          </li> : <></>}
        <li className="nav-item">
          <a title="About Us" href={process.env.PUBLIC_URL + "/#about-us"}>
            {t('About Us.1')}
          </a>
        </li>

        <li className="nav-item">
          <a title="Services" href={process.env.PUBLIC_URL + "/#services"}>
            {t('Services.1')}
          </a>
        </li>

        <li className="nav-item">
          <a title="Prediction" href={process.env.PUBLIC_URL + "/#our-team"}>
            {t('Prediction.1')}
          </a>
        </li>

        <li className="nav-item">
          <a
            title="Testimonials"
            href={process.env.PUBLIC_URL + "/offers"}
          >
            {t('Offers.1')}
          </a>
        </li>
        <li
          className={
            "nav-item " +
            (window.location.pathname.includes("/products/Endodonti") ||
              window.location.pathname.includes("/product")
              ? "current-nav-item"
              : "")
          }
        >
          <a title="News" href={process.env.PUBLIC_URL + "/products/Endodonti"}>
            {t('Products.1')}
          </a>
        </li>
        <li className="nav-item">
          <a title="News"
            href={process.env.PUBLIC_URL + "/fgm"}
          >
            FGM
          </a>
        </li>

        <li className="nav-item">
          <a title="Contacts" href={process.env.PUBLIC_URL + "/#contacts"}>
            {t('Contacts.1')}
          </a>
        </li>
        <LangSelector />

        <li className="nav-item">
          {userInfo ? (
            <li className="nav-item dropdown">
              <Link to="#">
                {userInfo.name}
                <i className="fa fa-caret-down"></i>
              </Link>
              <ul className="dropdown-content">
                <li>
                  <a href={process.env.PUBLIC_URL + "/dashboard"}>Dashboard</a>
                </li>
                <li>
                  <Link to="#signout" onClick={signoutHandler}>
                    {t("Sign Out.1")}
                  </Link>
                </li>
              </ul>
            </li>
          ) :
            (<li id="signin" className="nav-item">
              <a to="signin" title="Signin" href="/signin" smooth={true} duration={700}>{t('Signin.1')}</a>
            </li>)}
        </li>
      </ul>
    </nav >
  );
};

export default ModalMenuPrimary;
