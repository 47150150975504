import React from 'react';
import { useTranslation } from 'react-i18next';

function HomeAboutFeatures() {
    const { t } = useTranslation();
    return (
        <div id="features" className="wrapper spacer p-top-xl">
            <div className="row gutter-width-md with-pb-lg">
                <div className="col-sm">
                    <div className="feature-item">
                        <h2>{t('Our Values.1')}</h2>
                    </div>
                </div>

                <div className="col-sm">
                    <div className="feature-item">
                        <h4 style={{ color: "#566491" }}>{t('Dental Products.1')}</h4>
                        <p>{t('We offer the highest quality.1')}</p>
                    </div>
                </div>

                <div className="col-sm">
                    <div className="feature-item">
                        <h4 style={{ color: "#566491" }}>{t('Dental Equipment.1')}</h4>
                        <p>{t('We offer.1')}</p>
                    </div>
                </div>

                <div className="col-sm">
                    <div className="feature-item">
                        <h4 style={{ color: "#566491" }}>{t('Dental Services.1')}</h4>
                        <p>{t('We offer various dental services.1')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAboutFeatures;
