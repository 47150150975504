export const OFFERS_CREATE_REQUEST = 'OFFERS_CREATE_REQUEST';
export const OFFERS_CREATE_SUCCESS = 'OFFERS_CREATE_SUCCESS';
export const OFFERS_CREATE_FAIL = 'OFFERS_CREATE_FAIL';
export const OFFERS_CREATE_RESET = 'OFFERS_CREATE_RESET';

export const OFFERS_LIST_REQUEST = 'OFFERS_LIST_REQUEST';
export const OFFERS_LIST_SUCCESS = 'OFFERS_LIST_SUCCESS';
export const OFFERS_LIST_FAIL = 'OFFERS_LIST_FAIL';

export const OFFERS_DELETE_REQUEST = 'OFFERS_DELETE_REQUEST';
export const OFFERS_DELETE_SUCCESS = 'OFFERS_DELETE_SUCCESS';
export const OFFERS_DELETE_FAIL = 'OFFERS_DELETE_FAIL';

export const OFFERS_UPDATE_REQUEST = 'OFFERS_UPDATE_REQUEST';
export const OFFERS_UPDATE_SUCCESS = 'OFFERS_UPDATE_SUCCESS';
export const OFFERS_UPDATE_FAIL = 'OFFERS_UPDATE_FAIL';
