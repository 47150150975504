import React, { Fragment, useRef, useState, useEffect } from 'react';
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  createProduct,
  deleteProduct,
  listProducts,
} from '../actions/productActions';
import LoadingBox from '../components/loadmore/LoadMore';
import MessageBox from '../components/MessageBox';
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_DELETE_RESET,
} from '../constants/productConstants';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from "react-highlight-words";
export default function ProductListScreen() {
  const { pageNumber = 1 } = useParams();
  const navigate = useNavigate();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;
  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;
  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  let data = [];
  if (products !== undefined) {
    Object.keys(products).map((key) => {
      let obj = {
        key: key,
        id: products !== undefined ? products[key] !== undefined ? products[key]._id : "-" : "-",
        name: products !== undefined ? products[key] !== undefined ? products[key].name : "-" : "-",
        price: products !== undefined ? products[key] !== undefined ? products[key].price : "-" : "-",
        category: products !== undefined ? products[key] !== undefined ? products[key].category : "-" : "-",
        brand: products !== undefined ? products[key] !== undefined ? products[key].brand : "-" : "-",
        actions: <>    <Button
          type="button"
          className="small"
          style={{ fontSize: "16px" }}
          onClick={() =>
            navigate(`/product/${products[key]._id}/edit`)
          }
        >
          Edit
        </Button>
          <Button
            type="button"
            className="small"
            style={{ fontSize: "16px" }}
            onClick={(event) => deleteHandler(event, products[key])}
          >
            Delete
          </Button>
        </>
      }
      data.push(obj);
    })
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '30%',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'PRICE',
      dataIndex: 'price',
      key: 'price',
      width: '20%',
      ...getColumnSearchProps('price'),
      sorter: (a, b) => a.price - b.price,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'CATEGORY',
      dataIndex: 'category',
      key: 'category',
      width: '20%',
      ...getColumnSearchProps('category'),
    },
    {
      title: 'BRAND',
      dataIndex: 'brand',
      key: 'brand',
      width: '20%',
      ...getColumnSearchProps('brand'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      ...getColumnSearchProps('actions'),
    },
  ];
  useEffect(() => {
    if (successCreate) {
      dispatch({ type: PRODUCT_CREATE_RESET });
      navigate(`/product/${createdProduct._id}/edit`);
    }
    if (successDelete) {
      dispatch({ type: PRODUCT_DELETE_RESET });
    }
    dispatch(
      listProducts({ seller: userInfo !== null ? userInfo._id : '', pageNumber })
    );
  }, [
    createdProduct,
    dispatch,
    navigate,
    successCreate,
    successDelete,
    userInfo !== null ? userInfo._id : '',
    pageNumber,
  ]);
  const deleteHandler = (event, product) => {
    event.preventDefault();
    if (window.confirm('Are you sure to delete?')) {
      dispatch(deleteProduct(product._id));
    }
  };
  const createHandler = (e) => {
    e.preventDefault();
    dispatch(createProduct());
  };
  return (
    <div>
      {loadingDelete && <LoadingBox></LoadingBox>}
      {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
      {loadingCreate && <LoadingBox></LoadingBox>}
      {errorCreate && <MessageBox variant="danger">{errorCreate}</MessageBox>}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Fragment>
          <MetaTags>
            <meta charSet="UTF-8" />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta name="description" content="" />
            <meta name="keywords" content="" />
            <meta name="robots" content="index, follow, noodp" />
            <meta name="googlebot" content="index, follow" />
            <meta name="google" content="notranslate" />
            <meta name="format-detection" content="telephone=no" />
          </MetaTags>
          <div className="row" style={{ display: "flex", justifyContent: "end" }}>
            <Button type="button" className="primary" onClick={createHandler}>
              Create Product
            </Button>
          </div>
          <div style={{ overflowX: "auto", marginTop: "5%" }}>
            <Table columns={columns} dataSource={data} />;
          </div>
        </Fragment>
      )
      }
    </div >
  );
}