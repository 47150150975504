import React from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

const PageTitleHome = () => {
    const { t, i18n } = useTranslation();

    return (
        <section id="page-title" className="block with-img with-information-items" style={{ backgroundImage: `url('assets/img/placeholder/materialiDentali.jpeg')` }}>
            <div className="wrapper d-flex">
                <div className="align-self-center w-100">
                    <div className="title">
                        <h1 className="large">{t('Welcome to ProDental.1')}! </h1>
                    </div>

                    <div className="description spacer p-top-lg text-max-width">
                        <p>{t('Our priority is you.1')}!</p>
                    </div>

                    <div className="spacer p-top-lg no-space">
                        <Link to="about-us" href="#about-us" className="btn btn-primary scroll-to-id">{t('Learn more.1')}</Link>
                    </div>
                </div>
            </div>

            <div className="page-title-bg-color"></div>
        </section>
    );
};

export default PageTitleHome;
