const {
    CATEGORIES_LIST_REQUEST,
    CATEGORIES_LIST_SUCCESS,
    CATEGORIES_LIST_FAIL,
    CATEGORIES_DETAILS_REQUEST,
    CATEGORIES_DETAILS_SUCCESS,
    CATEGORIES_DETAILS_FAIL,
    CATEGORIES_CREATE_FAIL,
    CATEGORIES_CREATE_REQUEST,
    CATEGORIES_CREATE_SUCCESS,
    CATEGORIES_CREATE_RESET,
    CATEGORIES_UPDATE_REQUEST,
    CATEGORIES_UPDATE_SUCCESS,
    CATEGORIES_UPDATE_FAIL,
    CATEGORIES_UPDATE_RESET,
    CATEGORIES_DELETE_REQUEST,
    CATEGORIES_DELETE_SUCCESS,
    CATEGORIES_DELETE_FAIL,
    CATEGORIES_DELETE_RESET,
} = require('../constants/categoriesConstant');
export const categoriesListReducer = (state = { loadig: true, categoriess: [] }, action) => {
    switch (action.type) {
        case CATEGORIES_LIST_REQUEST:
            return { loading: true };
        case CATEGORIES_LIST_SUCCESS:
            return { loading: false, categories: action.payload };
        case CATEGORIES_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
export const categoriesDetailsReducer = (
    state = { categories: {}, loadig: true }, action) => {
    switch (action.type) {
        case CATEGORIES_DETAILS_REQUEST:
            return { loading: true };
        case CATEGORIES_DETAILS_SUCCESS:
            return { loading: false, categories: action.payload };
        case CATEGORIES_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
export const categoriesCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CATEGORIES_CREATE_REQUEST:
            return { loading: true };
        case CATEGORIES_CREATE_SUCCESS:
            return { loading: false, success: true, categories: action.payload };
        case CATEGORIES_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case CATEGORIES_CREATE_RESET:
            return {};
        default:
            return state;
    }
};
export const categoriesUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CATEGORIES_UPDATE_REQUEST:
            return { loading: true };
        case CATEGORIES_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case CATEGORIES_UPDATE_FAIL:
            return { loading: false, error: action.error };
        case CATEGORIES_UPDATE_RESET:
            return {};
        default:
            return state;
    }
};
export const categoriesDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CATEGORIES_DELETE_REQUEST:
            return { loading: true };
        case CATEGORIES_DELETE_SUCCESS:
            return { loading: false, success: true };
        case CATEGORIES_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case CATEGORIES_DELETE_RESET:
            return {};
        default:
            return state;
    }
};