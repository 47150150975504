import React, { Component } from 'react';
import { Link } from 'react-scroll';
import { Translation } from 'react-i18next';
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useNavigate } from 'react-router-dom'

class HomeHeaderMenuPrimary extends Component {
    constructor() {
        super();
        this.state = {
            over: false,
            pushed: false,
        };

        this.scrollEvent = this.scrollEvent.bind(this);
        this.set = this.set.bind(this);
    };

    componentDidMount() {
        window.addEventListener('scroll', this.scrollEvent, { passive: true });
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollEvent, { passive: true });
    };

    removeActive() {
        if (document.querySelector('.active')) {
            let actives = document.querySelector('.active');

            if (actives !== null) {
                actives.classList.remove("active");
            }
        }
    };


    scrollEvent() {
        let offset = 76;
        const offsets = [];
        const link_names = [];

        offsets.push(document.getElementById("about-us").offsetTop);
        offsets.push(document.getElementById("services").offsetTop);
        offsets.push(document.getElementById("our-team").offsetTop);
        offsets.push(document.getElementById("testimonials").offsetTop);
        offsets.push(document.getElementById("news").offsetTop);
        offsets.push(document.getElementById("contacts").offsetTop);
        //offsets.push( document.getElementById( "signin" ).offsetTop );

        link_names.push(document.getElementById("nav-about"));
        link_names.push(document.getElementById("nav-services"));
        link_names.push(document.getElementById("nav-teams"));
        link_names.push(document.getElementById("nav-testimonials"));
        link_names.push(document.getElementById("nav-news"));
        link_names.push(document.getElementById("nav-contacts"));
        //link_names.push( document.getElementById( "nav-signin" ) );

        if (!this.state.pushed) {
            if (window.scrollY < offsets[0]) {
                this.removeActive();
            }

            for (let i = 0; i < offsets.length; i++) {
                if (window.scrollY + offset > offsets[i]) {
                    this.removeActive();
                    link_names[i].classList.add('active');
                }
            }
        }
    };

    handleClick(e) {
        this.setState({ pushed: true })
        this.removeActive();

        let active = e.target;
        active.parentNode.classList.add("active");

        setTimeout(this.set, 800);
    };
    handleClickFGM() {
        const navigate = useNavigate();
        navigate("/fgm")
    }

    set() {
        this.setState({ pushed: false })
    };
    render() {
        return (
            <nav className="menu-primary">
                <ul className="nav">
                    <li id="nav-about" className="nav-item">
                        <Link onClick={(e) => this.handleClick(e)} title="About Us" to="about-us" href="#about-us">
                            <Translation>
                                {
                                    (t, { i18n }) => <p>{t('About Us.1')}</p>
                                }
                            </Translation>
                        </Link>
                    </li>

                    <li id="nav-services" className="nav-item">
                        <Link onClick={(e) => this.handleClick(e)} title="Services" to="services" href="#services">
                            <Translation>
                                {
                                    (t, { i18n }) => <p>{t('Services.1')}</p>
                                }
                            </Translation>
                        </Link>
                    </li>


                    <li id="nav-teams" className="nav-item">
                        <Link onClick={(e) => this.handleClick(e)} title="Prediction" to="our-team" href="#our-team">
                            <Translation>
                                {
                                    (t, { i18n }) => <p>{t('Prediction.1')}</p>
                                }
                            </Translation>
                        </Link>
                    </li>

                    <li id="nav-testimonials" className="nav-item">
                        <Link onClick={(e) => this.handleClick(e)} title="Testimonials" to="testimonials" href="#testimonials">
                            <Translation>
                                {
                                    (t, { i18n }) => <p>{t('Offers.1')}</p>
                                }
                            </Translation>
                        </Link>
                    </li>

                    <li id="nav-news" className="nav-item">
                        <Link onClick={(e) => this.handleClick(e)} title="News" to="news" href="#news">
                            <Translation>
                                {
                                    (t, { i18n }) => <p>{t('Products.1')}</p>
                                }
                            </Translation>
                        </Link>
                    </li>

                    <li id="nav-testimonials" className="nav-item">
                        <a href='/fgm' >
                            <p>FGM</p>
                        </a>
                    </li>

                    <li id="nav-contacts" className="nav-item">
                        <Link onClick={(e) => this.handleClick(e)} title="Contacts" to="contacts" href="#contacts">
                            <Translation>
                                {
                                    (t, { i18n }) => <p>{t('Contacts.1')}</p>
                                }
                            </Translation>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <a href='products/Endodonti'>
                            <ShoppingCartIcon style={{ marginTop: "21px", marginBottom: "13px" }} />
                        </a>
                    </li>
                </ul>
            </nav>
        );
    };
};

export default HomeHeaderMenuPrimary;
